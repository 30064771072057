import { Guid } from '@komo-tech/core/models/Guid';
import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { asBoolean } from '@komo-tech/core/utils/boolean';

import { LiveQuizAnswer } from './LiveQuizAnswer';
import { LiveQuizAnswerTypes } from './LiveQuizAnswerTypes';
import { LiveQuizQuestionTypes } from './LiveQuizQuestionTypes';
import { LiveScreen } from './LiveScreen';
import { LiveScreenTypes } from './LiveScreenTypes';

export class LiveQuizQuestionScreen implements LiveScreen {
  id: Guid;
  type = LiveScreenTypes.QuizQuestion;
  questionType: LiveQuizQuestionTypes = LiveQuizQuestionTypes.Text;
  questionText: string = '';
  questionImageUrl: string = '';
  hideQuestionText: boolean = false;

  get QuestionImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.questionImageUrl);
  }

  shuffleAnswers: boolean = true;
  order: number = 1;
  isSurvey: boolean = false;
  seconds: number = 20;
  answerType: LiveQuizAnswerTypes = LiveQuizAnswerTypes.Text;
  answers: LiveQuizAnswer[] = [];

  constructor(props?: Partial<LiveQuizQuestionScreen>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrEmpty(props.id);
    this.answers = props.answers
      ? props.answers.map((x: any) => new LiveQuizAnswer(x))
      : [];
    this.hideQuestionText = asBoolean(props.hideQuestionText);
  }

  getMilliseconds(): number {
    const seconds = this?.seconds || 20;
    return Math.max(1, seconds) * 1000;
  }

  getReadingTime = () => {
    let wordLength = (this.questionText || '')
      .split(' ')
      .map((w) => w.trim()).length;
    if (wordLength <= 0) {
      wordLength = 5;
    }
    return Math.max(1000, wordLength * 200);
  };
}
