import { Guid } from '@komo-tech/core/models/Guid';
import { IHasOrder } from '@komo-tech/core/models/IHasOrder';
import { mapArray, updateOrderProp } from '@komo-tech/core/utils/array';
import { classFromJsonOrFallback } from '@komo-tech/core/utils/type';

export class QuestionPoolModel {
  public slots: QuestionPoolSlot[] = [];

  constructor(props?: Partial<QuestionPoolModel>) {
    props = props || {};
    Object.assign(this, props);

    this.slots = mapArray(props.slots, (x) => new QuestionPoolSlot(x));
  }

  static fromJson(json: string) {
    return classFromJsonOrFallback(
      QuestionPoolModel,
      json,
      new QuestionPoolModel()
    );
  }

  static initWithSpecificQuestions(questionIds: Guid[] = []) {
    return new QuestionPoolModel({
      slots: questionIds.map((questionId, i) =>
        QuestionPoolSlot.newSpecificQuestionSlot(i + 1, questionId)
      )
    });
  }

  removeSlotImmutable(slotId: Guid) {
    return new QuestionPoolModel({
      slots: updateOrderProp(
        this.slots
          .filter((x) => !x.id.equals(slotId))
          .map((x) => new QuestionPoolSlot(x))
      )
    });
  }

  addRandomQuestionSlotImmutable() {
    return new QuestionPoolModel({
      slots: [
        ...this.slots,
        QuestionPoolSlot.newRandomQuestionSlot(this.slots.length + 1)
      ]
    });
  }

  addSpecificQuestionSlotImmutable(questionId: Guid) {
    return new QuestionPoolModel({
      slots: [
        ...this.slots,
        QuestionPoolSlot.newSpecificQuestionSlot(
          this.slots.length + 1,
          questionId
        )
      ]
    });
  }

  getRandomQuestionSlotCount(): number {
    return this.slots.filter(
      (x) => x.type === QuestionPoolSlotTypes.RandomQuestion
    ).length;
  }
}

export class QuestionPoolSlot implements IHasOrder {
  id: Guid;
  order: number;
  type: QuestionPoolSlotTypes;
  questionId?: Guid;

  constructor(props?: Partial<QuestionPoolSlot>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.questionId = Guid.valueOrNull(props.questionId);
  }

  static newRandomQuestionSlot(order: number) {
    return new QuestionPoolSlot({
      order: order,
      type: QuestionPoolSlotTypes.RandomQuestion
    });
  }

  static newSpecificQuestionSlot(order: number, questionId: Guid) {
    return new QuestionPoolSlot({
      order: order,
      type: QuestionPoolSlotTypes.SpecificQuestion,
      questionId: questionId
    });
  }
}

export enum QuestionPoolSlotTypes {
  'SpecificQuestion' = 'SpecificQuestion',
  'RandomQuestion' = 'RandomQuestion'
}
