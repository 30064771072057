import { capitaliseWords } from './capitaliseWords';

export const addSpacesOnCaps = (
  text: string,
  capitaliseFirstWord: boolean = true
) => {
  if (!text) return text;
  let compiled = text.replace(/([A-Z])/g, ' $1').trim();
  if (capitaliseFirstWord) compiled = capitaliseWords(compiled);
  return compiled;
};
