import {
  OptionModel,
  toOptionModels
} from '@komo-tech/core/models/OptionModel';
import { classFromJsonOrFallback } from '@komo-tech/core/utils/type';
import { immerable } from 'immer';

import { ICardIdentifier } from '@/common/models/CardIdentifier';
import { CardSubTypes } from '@/common/models/CardSubTypes';
import { CardTypes } from '@/common/models/CardTypes';

export class ScoreConfiguration {
  [immerable] = true;

  Trigger: ScoreTriggerTypes = 'Disabled';
  Value?: number;

  constructor(props: Partial<ScoreConfiguration> = {}) {
    Object.assign(this, props);
  }

  static fromJson(json: string) {
    return classFromJsonOrFallback(
      ScoreConfiguration,
      json,
      new ScoreConfiguration({})
    );
  }
}

export enum ScoreTriggerTypesEnum {
  Disabled = 'Disabled',
  DataCaptured = 'DataCaptured',
  GameplayEnded = 'GameplayEnded'
}

export type ScoreTriggerTypes =
  | ScoreTriggerTypesEnum
  | 'Disabled'
  | 'DataCaptured'
  | 'GameplayEnded';

export const AllScoreTriggerTypeOptions = toOptionModels(
  Object.values(ScoreTriggerTypesEnum),
  true
);

export const ScoreTriggerTypeOptionsDataCaptureOnly = toOptionModels(
  Object.values(ScoreTriggerTypesEnum).filter(
    (x) => x !== ScoreTriggerTypesEnum.GameplayEnded
  ),
  true
);

export const ScoreTriggerTypeOptionsGameplayOnly = toOptionModels(
  Object.values(ScoreTriggerTypesEnum).filter(
    (x) => x !== ScoreTriggerTypesEnum.DataCaptured
  ),
  true
);

export type ScoreConfigOptions =
  | {
      variant: 'customizable';
      triggerOptions: OptionModel[];
    }
  | {
      variant: 'inherited-from-card';
    }
  | { variant: 'no-scoring' };

export interface ICardDescriptor extends ICardIdentifier {
  hasCompetition: boolean;
}

export const getScoreConfigOptions = (
  cardDescriptor: ICardDescriptor
): ScoreConfigOptions => {
  switch (cardDescriptor.type) {
    case CardTypes.MemoryChallenge:
    case CardTypes.Predictive:
    case CardTypes.TreasureHunt:
    case CardTypes.Trivia:
      return {
        variant: 'inherited-from-card'
      };

    // cards that have gameplays
    case CardTypes.Checklist:
    case CardTypes.NumberGuess:
    case CardTypes.Quiz:
    case CardTypes.Canvas:
    case CardTypes.Upload:
    case CardTypes.InstantWin:
    case CardTypes.Picker:
    case CardTypes.ScratchAndWin:
    case CardTypes.Swiper:
    case CardTypes.SpinToWin:
      return {
        variant: 'customizable',
        triggerOptions: cardDescriptor.hasCompetition
          ? AllScoreTriggerTypeOptions
          : ScoreTriggerTypeOptionsGameplayOnly
      };

    // Live survey doesn't have an ended event, so it's data capture only
    case CardTypes.LiveSurvey:
      return {
        variant: 'customizable',
        triggerOptions: ScoreTriggerTypeOptionsDataCaptureOnly
      };

    case CardTypes.Carousel:
      // carousel votes\"item interactions" aren't "Gameplay"s on the backend
      // Todo - enable this if carousel item interactions are made to inherit from "Gameplay" - also add the scoring tab into CarouselCardTabs
      // if (cardDescriptor.subType === CardSubTypes.Vote) {
      //   return {
      //     variant: 'customizable',
      //     triggerOptions: ScoreTriggerTypeOptionsDataCaptureOnly
      //   };
      // }

      return {
        variant: 'no-scoring'
      };

    // Cards with no data capture or gameplays can't be scored at the moment
    case CardTypes.Embed:
    case CardTypes.Leaderboard:
    case CardTypes.Fact:
    case CardTypes.Image:
    case CardTypes.Countdown:
    case CardTypes.Video:
    default:
      return { variant: 'no-scoring' };
  }
};
