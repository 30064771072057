import { immerable } from 'immer';

export class LiveSurveyGameplayProperties {
  [immerable] = true;

  constructor(props?: Partial<LiveSurveyGameplayProperties>) {
    props = props || {};
    Object.assign(this, props);
  }
}
