import { addSpacesOnCaps } from '../_internal/addSpacesOnCaps';

export interface OptionModel<T = string> {
  label: string;
  value: T;
  disabled?: boolean;
  description?: string;
  /**
   * Used for grouping options, only works in mantine-based selects
   */
  group?: string;
}

export function instanceOfOptionModel(object: any): object is OptionModel {
  return 'value' in object;
}

export interface OptionModelWithUniqueId<T = string> extends OptionModel<T> {
  id: string;
}

export interface GroupedOptionModel<T = OptionModel<string>> {
  label: string;
  options: T[];
}

export function instanceOfGroupedOptionModel(
  object: any
): object is GroupedOptionModel {
  return 'options' in object;
}

export interface GroupedOptionModelWithUniqueId<T = OptionModel<string>>
  extends GroupedOptionModel<T> {
  id: string;
}

export const toOptionModels = (
  items: readonly any[],
  addSpacesForLabelOnCaps?: boolean
) => {
  return !items || !items.length
    ? ([] as OptionModel<string>[])
    : items.map((i) => {
        const item: OptionModel<string> = {
          value: i.toString(),
          label: addSpacesForLabelOnCaps ? addSpacesOnCaps(i) : i
        };
        return item;
      });
};

export function enumToOptionModels(
  enumType: any,
  addSpacesForLabelOnCaps?: boolean
) {
  const items = Object.values(enumType);

  return !items || !items.length
    ? ([] as OptionModel<string>[])
    : items.map((i) => {
        const item: OptionModel<string> = {
          value: i.toString(),
          label: addSpacesForLabelOnCaps
            ? addSpacesOnCaps(i.toString())
            : i.toString()
        };
        return item;
      });
}

export function toOptionModelsWithPredicate<TData = any, TOptionValue = any>(
  items: TData[],
  predicate: (item: TData) => OptionModel<TOptionValue>
) {
  return !items || !items.length
    ? ([] as OptionModel<TOptionValue>[])
    : items.map(predicate);
}

export function toOptionModelsUsingName(items: any[]) {
  return !items || !items.length
    ? ([] as OptionModel[])
    : items.map((i) => ({
        value: i.id.toString(),
        label: i.name
      }));
}
