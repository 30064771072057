import { Guid } from '@komo-tech/core/models/Guid';
import { orderByAscending } from '@komo-tech/core/models/IHasOrder';
import { mapArray } from '@komo-tech/core/utils/array';

import { ButtonDataModel } from '@/common/models/ButtonDataModel';
import { CardTypes } from '@/common/models/CardTypes';
import { SitePageProperties } from '@/common/models/pages/shared/SitePageProperties';
import { SiteCard } from '@/common/models/SiteCard';
import { SiteCardHelper } from '@/common/models/SiteCardHelper';
import {
  getPrimaryBackgroundColor,
  getPrimaryTextColor
} from '@/common/utils/ButtonFunctions';

import { SwiperProperties } from '../shared/SwiperProperties';
import { FrontSwiperOptionGroup } from './FrontSwiperOptionGroup';

export class FrontSwiper implements SiteCard {
  id: Guid;
  siteId: Guid;
  type: CardTypes;
  optionGroups: FrontSwiperOptionGroup[];
  properties?: SwiperProperties;

  constructor(props?: Partial<FrontSwiper>) {
    props = props || {};
    Object.assign(this, props);
    SiteCardHelper.applyDefaults(this, props);
    this.optionGroups = mapArray(
      props.optionGroups,
      (x) => new FrontSwiperOptionGroup(x)
    );
    this.properties = new SwiperProperties(props.properties);
    this.sanitiseGroups();
  }

  sanitiseGroups() {
    this.optionGroups = this.optionGroups.sort(orderByAscending);
    this.optionGroups.forEach((o, i) => {
      o.order = i + 1;
    });
  }

  getViewAnswersButton(properties: SitePageProperties) {
    return this.getButton(properties, {
      backgroundColor: this.properties.ViewAnswersButtonBackgroundColor,
      textColor: this.properties.ViewAnswersButtonColor,
      text:
        this.properties.ViewAnswersButtonText ||
        this.properties.DefaultViewAnswersText
    });
  }

  getFallbackButtonColors(properties: SitePageProperties) {
    return {
      background: getPrimaryBackgroundColor({
        card: this,
        properties
      }),
      color: getPrimaryTextColor({
        card: this,
        properties
      })
    };
  }

  private getButton(
    properties: SitePageProperties,
    options: {
      backgroundColor?: string;
      textColor?: string;
      text: string;
    }
  ) {
    return new ButtonDataModel({
      backgroundColor: getPrimaryBackgroundColor({
        overrideColor: options.backgroundColor,
        card: this,
        properties
      }),
      color: getPrimaryTextColor({
        overrideColor: options.textColor,
        card: this,
        properties
      }),
      text: options.text
    });
  }
}
