import { Guid } from '@komo-tech/core/models/Guid';
import { asNumber } from '@komo-tech/core/utils/number';

export class QuizQuestionOptionWeighting {
  resultId: Guid;
  points: number;

  constructor(partial: Partial<QuizQuestionOptionWeighting>) {
    partial = partial || {};
    Object.assign(this, partial);
    this.resultId = Guid.valueOrEmpty(partial.resultId);
    this.points = asNumber(partial.points, 0);
  }
}
