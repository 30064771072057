import { Guid } from '@komo-tech/core/models/Guid';

import { QuizBridge } from './QuizBridge';
import { QuizQuestion } from './QuizQuestion';

type ScreenTypes = 'question' | 'bridge' | 'result';

export class QuizScreen {
  id: Guid;
  question?: QuizQuestion;
  bridge?: QuizBridge;

  get type(): ScreenTypes {
    if (this.question) return 'question';
    if (this.bridge) return 'bridge';
    return 'result';
  }

  constructor(props: Partial<QuizScreen> = {}) {
    this.id = Guid.valueOrNew(props.id);
    if (props.question) this.question = new QuizQuestion(props.question);
    if (props.bridge) this.bridge = new QuizBridge(props.bridge);
  }

  setAsQuestionScreen(question: QuizQuestion) {
    this.question = question;
    this.bridge = null;
  }

  setAsBridgeScreen(bridge: QuizBridge) {
    this.bridge = bridge;
    this.question = null;
  }

  static resolveQuestionIndex = (
    quizScreens: QuizScreen[],
    questionId: Guid
  ): number => {
    return quizScreens.findIndex((s) =>
      Guid.equals(s?.question?.id, questionId)
    );
  };
}
