import { Guid } from '@komo-tech/core/models/Guid';

import { ITriviaGameScreen } from './ITriviaGameScreen';
import { TriviaGameScreenTypes } from './TriviaGameScreenTypes';

export class TriviaGameAnswersScreen implements ITriviaGameScreen {
  id: Guid;
  public type = TriviaGameScreenTypes.Answers;
  actionHeader: string;
  actionSubheader: string;
  actionLink: string;
  actionButtonText: string;
  facebookLink: string;
  instagramLink: string;
  twitterLink: string;
  youtubeLink: string;
  webLink: string;
  linkedinLink: string;

  hasSocialMediaLinks() {
    return this.facebookLink ||
      this.twitterLink ||
      this.instagramLink ||
      this.webLink ||
      this.linkedinLink
      ? true
      : false;
  }

  hasCallToAction() {
    const hasHeader = this.actionHeader ? true : false;
    const hasSubHeader = this.actionSubheader ? true : false;
    const hasButtonText = this.actionButtonText ? true : false;
    const hasLink = this.actionLink ? true : false;
    return hasHeader && hasSubHeader && hasButtonText && hasLink;
  }
  constructor(props: Partial<TriviaGameAnswersScreen> = {}) {
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
  }
}

export class TriviaAnswerScreenAnswer {
  questionId: Guid;
  answerId?: Guid;

  constructor(props: Partial<TriviaAnswerScreenAnswer> = {}) {
    Object.assign(this, props);
    this.questionId = Guid.valueOrNew(props.questionId);
    this.answerId = Guid.valueOrUndefined(props.answerId);
  }
}
