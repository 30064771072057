import { asBoolean } from '@komo-tech/core/utils/boolean';

import { CardLayout } from '@/common/models/CardLayout';

export class QuizQuestionProperties {
  videoUrl?: string;
  ExplainerText?: string;
  ExplainerTextEditorStateJson?: string;
  MarketResearchEnabled?: boolean;
  Layout?: CardLayout;
  HideQuestionText?: boolean;
  InternalName?: string;

  constructor(props: Partial<QuizQuestionProperties> = {}) {
    Object.assign(this, props);

    if (props.MarketResearchEnabled) {
      this.MarketResearchEnabled = asBoolean(props.MarketResearchEnabled);
    }
    if (props.HideQuestionText) {
      this.HideQuestionText = asBoolean(props.HideQuestionText);
    }
  }
}
