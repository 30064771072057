import { Guid } from '@komo-tech/core/models/Guid';
import { mapArray, tryReplaceItemInArray } from '@komo-tech/core/utils/array';
import max from 'lodash/max';
import orderBy from 'lodash/orderBy';
import sum from 'lodash/sum';

import { ButtonDataModel } from '@/common/models/ButtonDataModel';
import { CardTypes } from '@/common/models/CardTypes';
import { SitePageProperties } from '@/common/models/pages/shared/SitePageProperties';
import { SiteCard } from '@/common/models/SiteCard';
import { SiteCardHelper } from '@/common/models/SiteCardHelper';
import {
  getPrimaryBackgroundColor,
  getPrimaryTextColor
} from '@/common/utils/ButtonFunctions';

import { FrontSwiperGameplay } from '../Front/FrontSwiperGameplay';
import { SwiperProperties } from '../shared/SwiperProperties';
import { AdminSwiperOption } from './AdminSwiperOption';
import { AdminSwiperOptionGroup } from './AdminSwiperOptionGroup';
import { AdminSwiperResult } from './AdminSwiperResult';

export class AdminSwiper implements SiteCard {
  id: Guid;
  siteId: Guid;
  type: CardTypes;
  optionGroups: AdminSwiperOptionGroup[];
  results: AdminSwiperResult[];
  properties: SwiperProperties;

  get modalHeaderProps() {
    return this.properties.ModalHeaderProps;
  }

  get modalBackgroundProps() {
    return this.properties.ModalBackgroundProps;
  }

  get totalSwipes() {
    return sum(this.optionGroups.map((x) => x.optionsLength));
  }

  constructor(props?: Partial<AdminSwiper>) {
    props = props || {};
    Object.assign(this, props);
    SiteCardHelper.applyDefaults(this, props);
    this.optionGroups = orderBy(
      mapArray(props.optionGroups, (x) => new AdminSwiperOptionGroup(x)),
      (x) => x.order
    );
    this.results = [];
    (props.results || []).forEach((r) => {
      if (r.isDefault) {
        this.results.unshift(new AdminSwiperResult(r));
      } else {
        this.results.push(new AdminSwiperResult(r));
      }
    }, this);
    this.properties = new SwiperProperties(props.properties);
  }

  getAllOptions() {
    const options: AdminSwiperOption[] = [];
    this.optionGroups.forEach((g) => {
      g.options.forEach((o) => {
        options.push(o);
      });
    });

    return options;
  }

  getGroup(id: Guid) {
    return this.optionGroups.find((x) => x.id.equals(id));
  }

  getResult(id: Guid) {
    return this.results.find((x) => x.id.equals(id));
  }

  getOrderForNewOptionGroup() {
    if (!this.optionGroups?.length) {
      return 1;
    }

    return max(this.optionGroups.map((x) => x.order)) + 1;
  }

  getModalButtonProps(properties: SitePageProperties) {
    return {
      color: getPrimaryTextColor({
        overrideColor: this.properties.ButtonTextColor,
        properties
      }),
      backgroundColor: getPrimaryBackgroundColor({
        overrideColor: this.properties.ButtonBackgroundColor,
        properties
      })
    };
  }

  getModalBackgroundImagePath() {
    return `site/${this.siteId}/card/${this.id}/modal-background`;
  }

  getSubheader(correctAnswers: number | string) {
    return `YOU GOT ${correctAnswers} OUT OF ${this.totalSwipes.toLocaleString()} CORRECT`;
  }

  getViewAnswersButton(properties: SitePageProperties) {
    return this.getButton(properties, {
      backgroundColor: this.properties.ViewAnswersButtonBackgroundColor,
      textColor: this.properties.ViewAnswersButtonColor,
      text:
        this.properties.ViewAnswersButtonText ||
        this.properties.DefaultViewAnswersText
    });
  }

  getFallbackButtonColors(properties: SitePageProperties) {
    return {
      backgroundColor: getPrimaryBackgroundColor({
        card: this,
        properties
      }),
      color: getPrimaryTextColor({
        card: this,
        properties
      })
    };
  }

  generateTestGameplay() {
    return new FrontSwiperGameplay({
      swiperId: this.id,
      userId: Guid.newGuid()
    });
  }

  cloneForTest(options?: GenerateTestGameplayOptions) {
    const card = new AdminSwiper(this);
    if (options?.editingGroup) {
      tryReplaceItemInArray(
        card.optionGroups,
        (x) => x.id.equals(options.editingGroup.id),
        options.editingGroup
      );
    }
    return card;
  }

  private getButton(
    properties: SitePageProperties,
    options: {
      backgroundColor?: string;
      textColor?: string;
      text: string;
    }
  ) {
    return new ButtonDataModel({
      backgroundColor: getPrimaryBackgroundColor({
        overrideColor: options.backgroundColor,
        card: this,
        properties
      }),
      color: getPrimaryTextColor({
        overrideColor: options.textColor,
        card: this,
        properties
      }),
      text: options.text
    });
  }
}

interface GenerateTestGameplayOptions {
  editingGroup?: AdminSwiperOptionGroup;
}
