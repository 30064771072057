import { Guid } from '@komo-tech/core/models/Guid';
import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { asBoolean } from '@komo-tech/core/utils/boolean';
import { immerable } from 'immer';

import { QrCodeFormValues } from '@/common/components/QrCode/QrCodeForm/QrCodeForm.types';
import { TreasureHuntDrawerProperties } from '@/common/models/treasure-hunt/shared/TreasureHuntDrawerProperties';
import { TreasureHuntProperties } from '@/common/models/treasure-hunt/shared/TreasureHuntProperties';

import { TreasureHuntCheckpointStatuses } from './TreasureHuntCheckpointStatuses';

export class TreasureHuntCheckpointProperties {
  [immerable] = true;

  Title: string;
  Description: string;
  ImageDataModelJson?: string;

  SimulatedStatus: TreasureHuntCheckpointStatuses =
    TreasureHuntCheckpointStatuses.Open;

  TopContent: string;
  TopContentStateJson: string;
  TopContentEnabled: boolean;
  BottomContent: string;
  BottomContentStateJson: string;
  BottomContentEnabled: boolean;

  QrCodeId: Guid;
  QrCodeSettingsJson: string = '{}';

  TextCodeInputPlaceholder: string = 'Enter the code here';
  TextCodesJson: string = '[]';

  DrawerPropertiesJson: string;

  CheckpointsListItemBackgroundColor: string;
  CheckpointsListItemTextColor: string;
  CheckpointsListItemScoreBackgroundColor: string;
  CheckpointsListItemScoreTextColor: string;

  CheckpointsListItemImageTextCodeBackgroundColor: string;
  CheckpointsListItemImageQrBackgroundColor: string;
  CheckpointsListItemImageTextColor: string;

  get QrCodeSettings(): QrCodeFormValues {
    const settings: QrCodeFormValues = JSON.parse(this.QrCodeSettingsJson);
    settings.image = ImageDataModel.fromJsonOrUrl(settings.image);

    return settings;
  }

  get TextCodes(): string[] {
    try {
      return JSON.parse(this.TextCodesJson);
    } catch (e) {
      return [];
    }
  }

  get Image(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.ImageDataModelJson);
  }

  get DrawerProperties(): TreasureHuntDrawerProperties {
    return TreasureHuntDrawerProperties.fromJsonOrNew(
      this.DrawerPropertiesJson,
      {
        headerTextColor: '',
        headerBackgroundColor: '',
        bodyTextColor: '',
        bodyBackgroundColor: ''
      }
    );
  }

  constructor(props?: Partial<TreasureHuntCheckpointProperties>) {
    props = props || {};
    Object.assign(this, props);
    this.TopContentEnabled = asBoolean(props.TopContentEnabled, true);
    this.BottomContentEnabled = asBoolean(props.BottomContentEnabled, true);
    this.QrCodeId = Guid.valueOrUndefined(props.QrCodeId);
  }

  stylingProperties(
    cardProperties: TreasureHuntProperties
  ): Partial<TreasureHuntCheckpointProperties> {
    const drawerStyles = this.DrawerProperties;
    const fallbackDrawerStyles = cardProperties.CheckpointDrawerProperties;

    return {
      CheckpointsListItemBackgroundColor:
        this.CheckpointsListItemBackgroundColor ||
        cardProperties.CheckpointsListItemBackgroundColor,
      CheckpointsListItemTextColor:
        this.CheckpointsListItemTextColor ||
        cardProperties.CheckpointsListItemTextColor,
      CheckpointsListItemScoreBackgroundColor:
        this.CheckpointsListItemScoreBackgroundColor ||
        cardProperties.CheckpointsListItemScoreBackgroundColor,
      CheckpointsListItemScoreTextColor:
        this.CheckpointsListItemScoreTextColor ||
        cardProperties.CheckpointsListItemScoreTextColor,
      CheckpointsListItemImageTextCodeBackgroundColor:
        this.CheckpointsListItemImageTextCodeBackgroundColor ||
        cardProperties.CheckpointsListItemImageTextCodeBackgroundColor,
      CheckpointsListItemImageQrBackgroundColor:
        this.CheckpointsListItemImageQrBackgroundColor ||
        cardProperties.CheckpointsListItemImageQrBackgroundColor,
      CheckpointsListItemImageTextColor:
        this.CheckpointsListItemImageTextColor ||
        cardProperties.CheckpointsListItemImageTextColor,
      DrawerProperties: new TreasureHuntDrawerProperties({
        headerTextColor:
          drawerStyles.headerTextColor || fallbackDrawerStyles.headerTextColor,
        headerBackgroundColor:
          drawerStyles.headerBackgroundColor ||
          fallbackDrawerStyles.headerBackgroundColor,
        bodyTextColor:
          drawerStyles.bodyTextColor || fallbackDrawerStyles.bodyTextColor,
        bodyBackgroundColor:
          drawerStyles.bodyBackgroundColor ||
          fallbackDrawerStyles.bodyBackgroundColor
      })
    };
  }
}
