import { Guid } from '@komo-tech/core/models/Guid';
import { utcDateOrNow } from '@komo-tech/core/utils/date';

import { CardResultProperties } from './card-result/CardResultProperties';

export class FrontCardResult {
  id: Guid;
  name: string;
  cardId: Guid;
  updatedAt: Date;
  createdAt: Date;
  properties: CardResultProperties;

  constructor(props?: Partial<FrontCardResult>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrEmpty(props.id);
    this.createdAt = utcDateOrNow(props.createdAt);
    this.updatedAt = utcDateOrNow(props.updatedAt);

    this.properties = new CardResultProperties(props.properties);
  }
}
