import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';

import { ValidationRegex } from './regex';

/**
 * Convert the specified value to a string, if empty string the default value
 * will be returned.
 *
 *   - String - return value (trimmed)
 *   - Null - return default
 *   - Undefined - return default
 *   - Number - return as string
 *   - Array - if 1 item, return as string, if many return
 *       joined string, if none, return default value
 *   - Otherwise return the
 *
 * @param value The value to convert.
 * @param defaultValue The default value to return if invalid string or empty.
 * @returns The converted string.
 */
export const asString = (value: any, defaultValue: string = '') => {
  if (isNil(value)) {
    return defaultValue;
  }
  if (isString(value)) {
    return value.length > 0 ? value.trim() : defaultValue;
  }
  if (isArray(value)) {
    switch (value.length) {
      case 0:
        return defaultValue;
      case 1:
        return asString(value[0]);
      default:
        return value.join(', ').trim();
    }
  }
  // Check array before object
  if (isObject(value)) {
    return defaultValue;
  }
  return value.toString().trim();
};

/**
 * Convert the specified value to a lower case string, if empty string
 * the default value will be returned.
 * @param value The value to convert.
 * @param defaultValue The default value to return if invalid string or empty.
 * @returns The converted string.
 */
export const asStringLower = (value: any, defaultValue: string = '') =>
  asString(value, defaultValue)?.toLowerCase();

/**
 * Truncates a string to the specified length if the length over this value.
 * @param value The value to restrict the length of.
 * @param length The length to restrict the text to.
 * @param suffix The string to truncate the text with, default is "...".
 * @returns The string with restricted length.
 */
export const truncateString = (
  value: string,
  length: number,
  suffix: string = '...'
) => {
  if (!value) {
    return '';
  }
  return value.length > length
    ? value.substr(0, Math.max(0, length - suffix.length)) + suffix
    : value;
};

/**
 * Compares two string values.
 * @param val1 The first value.
 * @param val2 The second value.
 * @param ignoreCase Indicates if case should be ignored (case insensitive).
 */
export const isStringEqual = (
  val1: string | undefined | null,
  val2: string | undefined | null,
  ignoreCase?: boolean
) => {
  return ignoreCase
    ? asStringLower(val1) === asStringLower(val2)
    : asString(val1) === asString(val2);
};

export const joinWordList = (
  items: string[],
  upperCaseFirstLetter?: boolean
): string => {
  const filtered = items.filter((x) => !!x);
  if (filtered.length === 0) {
    return '';
  }

  let words = '';
  for (let i = 0; i < filtered.length; i++) {
    if (i > 0) {
      words += i === filtered.length - 1 ? ' & ' : ', ';
    }
    words += filtered[i];
  }

  if (upperCaseFirstLetter && words.length > 2) {
    words = words[0].toLocaleUpperCase() + words.substring(1);
  }

  return words;
};

export const capitaliseWords = (text: string) => {
  return !text ? text : text.replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
};

export const capitaliseFirstWordOnly = (text: string) => {
  if (!text) return text;
  return capitaliseFirstWord(text.toLocaleLowerCase());
};
export const capitaliseFirstWord = (text: string) => {
  return !text ? text : text.charAt(0).toUpperCase() + text.slice(1);
};

export const addSpacesOnCaps = (
  text: string,
  capitaliseFirstWord: boolean = true
) => {
  if (!text) return text;
  let compiled = text.replace(/([A-Z])/g, ' $1').trim();
  if (capitaliseFirstWord) compiled = capitaliseWords(compiled);
  return compiled;
};

export const isEmail = (text: string) => {
  if (!text) return false;
  return ValidationRegex.email.test(String(text).toLowerCase());
};

export const compressConsecutiveWhitespace = (text: string) => {
  if (!text) return undefined;
  return text.replace(/\s+/g, ' ');
};

export const compressConsecutiveWhitespaceAndTrim = (text: string) => {
  if (!text) return undefined;
  return compressConsecutiveWhitespace(text.trim());
};

export function asModelOrFallback<T = any>(
  json: string,
  options?: {
    fallback?: T;
    dontLogError?: boolean;
  }
) {
  if (!json) return options?.fallback;
  try {
    return JSON.parse(json) as T;
  } catch (e) {
    if (!options?.dontLogError) {
      console.error(e);
    }
    return options?.fallback;
  }
}
export const sortByStringAscending = (a?: string, b?: string) => {
  return (a || '').localeCompare(b);
};

export const sortByStringDescending = (a?: string, b?: string) => {
  return (b || '').localeCompare(a);
};

export const splitCamelCase = (text: string) =>
  text.replace(/([a-z])([A-Z])/g, '$1 $2');

export const getCdnDownloadUrl = (url: string) => {
  const download = new URL(url);
  download.searchParams.set('download', 'true');
  return download.toString();
};

export const getExtensionFromUrl = (url: string) => {
  if (!url) return '';
  try {
    if (url.includes('resize/?name=') || url.includes('resize?name=')) {
      const split = url.split('?name=');
      const dirtyExtension = split[1].split(/[#?]/)[0].split('.').pop().trim();
      const extension = dirtyExtension.split('&')[0];
      return extension;
    } else {
      const dirtyExtension = url.split(/[#?]/)[0].split('.').pop().trim();
      const extension = dirtyExtension.split('&')[0];
      return extension;
    }
  } catch {
    return '';
  }
};

export const getFileNameFromUrl = (url: string) => {
  if (!url) return '';
  try {
    if (url.includes('resize/?name=') || url.includes('resize?name=')) {
      const split = url.split('?name=');
      return split[1].split(/[#?]/)[0].split('/').slice(-1).pop().trim();
    } else {
      return url.split(/[#?]/)[0].split('/').slice(-1).pop().trim();
    }
  } catch {
    return '';
  }
};

export const asSingularOrPlural = (noun: string, amount: number): string => {
  if (amount === 1) {
    return noun.at(-1) === 's' ? noun.slice(0, -1) : noun;
  }

  return noun.at(-1) === 's' ? noun : `${noun}${'s'}`;
};

export const asPlural = (noun: string): string => asSingularOrPlural(noun, 2);

export const hasWhitespaceOrNewLine = (text: string) =>
  text === '\n' || text === ' ';

export const hasUpperCaseCharacter = (text: string) => {
  if (!text) return false;
  for (let i = 0; i < text.length; ++i) {
    if (
      text[i].toUpperCase() === text[i] &&
      text[i] !== text[i].toLowerCase()
    ) {
      return true;
    }
  }
  return false;
};

export const padNumber = (
  value: number,
  targetLength: number,
  padCharacter?: string
) => {
  return String(value || 0).padStart(targetLength, padCharacter || '0');
};

export function isValidJson(
  value?: string,
  deserialize: typeof JSON.parse = JSON.parse
) {
  if (!value) {
    return true;
  }
  if (typeof value === 'string' && value.trim().length === 0) {
    return true;
  }

  try {
    deserialize(value);
    return true;
  } catch {
    return false;
  }
}

/**
 * This function takes any string and converts it to a random index of a given array
 * will always return the same index given the same string and array length
 * @param str string to be converted into a consistent random item in the array
 * @param arr array of items to choose from
 * @returns one item from array
 */
export function stringToArrayItem<T>(str: string, arr: Array<T>): T {
  if (!arr?.length) return undefined;

  // Simple hash function
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }

  // Ensure the hash is a positive number
  hash = Math.abs(hash);

  // Map the hash to one of the items in the array
  const index = hash % arr.length;
  return arr[index];
}
