import { ColorRGBA } from '@komo-tech/core/models/ColorRGBA';
import { asBoolean } from '@komo-tech/core/utils/boolean';

export class SwiperOptionGroupProperties {
  BackgroundColor?: string;
  HeaderTitle?: string;
  HeaderSubTitle?: string;

  OptionsFooterDisabled?: boolean;
  OptionsFooterBackgroundColor?: string = '#000000';
  OptionsFooterTextColor?: string = '#FFFFFF';
  OptionsFooterCounterHidden?: boolean = false;
  OptionsFooterSubtitleHidden?: boolean = false;

  DefaultRightText: string = 'Yes';
  RightButtonText?: string;
  RightButtonTextColor?: string;
  RightButtonBackgroundColor?: string = '#2b8a3e';

  get FallbackRightButtonTextColor() {
    return new ColorRGBA(this.RightButtonBackgroundColor).getContrastColor();
  }

  DefaultLeftText: string = 'No';
  LeftButtonText?: string;
  LeftButtonTextColor?: string;
  LeftButtonBackgroundColor?: string = '#c92a2a';

  get FallbackLeftButtonTextColor() {
    return new ColorRGBA(this.LeftButtonBackgroundColor).getContrastColor();
  }

  constructor(props: Partial<SwiperOptionGroupProperties> = {}) {
    Object.assign(this, props);
    this.OptionsFooterCounterHidden = asBoolean(
      props.OptionsFooterCounterHidden
    );
    this.OptionsFooterSubtitleHidden = asBoolean(
      props.OptionsFooterSubtitleHidden
    );
    this.OptionsFooterDisabled = asBoolean(props.OptionsFooterDisabled);
    if (!this.RightButtonText) {
      this.RightButtonText = this.DefaultRightText;
    }
    if (!this.LeftButtonText) {
      this.LeftButtonText = this.DefaultLeftText;
    }
  }
}
