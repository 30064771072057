import { mapArray } from '@komo-tech/core/utils/array';

import { CardSubTypes } from './CardSubTypes';
import { CardTypes } from './CardTypes';

export interface ICardIdentifier {
  type: CardTypes;
  subType?: CardSubTypes;
}

export class CardIdentifier implements ICardIdentifier {
  type: CardTypes;
  subType?: CardSubTypes;

  get id() {
    return !this.subType ? this.type : `${this.type}-${this.subType}`;
  }

  constructor(props?: Partial<ICardIdentifier>) {
    props = props || {};
    Object.assign(this, props);
  }

  equals(obj: ICardIdentifier): boolean {
    if (!obj) return false;
    if (!obj.subType) return this.type === obj.type;
    return this.type === obj.type && this.subType === obj.subType;
  }

  static fromId(id: string): CardIdentifier {
    const split = id.split('-');
    const type = split[0] as CardTypes;
    const subType = split[1] as CardSubTypes;
    return new CardIdentifier({ type: type, subType: subType });
  }

  static resolveId(identifier: ICardIdentifier) {
    return new CardIdentifier(identifier).id;
  }

  static fromValueOrFallback(
    value?: CardIdentifier,
    fallback?: CardIdentifier
  ) {
    if (!value) {
      return fallback;
    }
    return new CardIdentifier(value);
  }

  static arrayFromIds(ids: string[]) {
    return mapArray(ids, (id) => CardIdentifier.fromId(id));
  }
}
