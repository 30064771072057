import { Guid } from '@komo-tech/core/models/Guid';
import { orderByAscending } from '@komo-tech/core/models/IHasOrder';
import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { mapArray } from '@komo-tech/core/utils/array';

import { CardLayout } from '@/common/models/CardLayout';

import { QuizAnswerStyles } from './QuizAnswerStyles';
import { QuizQuestionOption } from './QuizQuestionOption';
import { QuizQuestionProperties } from './QuizQuestionProperties';

export class QuizQuestion {
  id: Guid;
  quizId: Guid;
  style: QuizAnswerStyles = QuizAnswerStyles.Single;
  text: string = '';
  imageUrl: string = '';

  get image(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.imageUrl);
  }

  order: number = 1;
  points?: number;
  options: QuizQuestionOption[] = [];
  optionsShuffled: boolean = true;
  imageEnabled: boolean = true; // now also including video, i.e. 'mediaEnabled'. will convert with cli task
  explainerEnabled: boolean = false;
  properties: QuizQuestionProperties;
  propertyDefinitionId?: Guid;

  get isPoll() {
    return this.style === QuizAnswerStyles.Poll;
  }

  get internalName() {
    return this.properties.InternalName;
  }

  set videoUrl(value) {
    this.properties.videoUrl = value;
  }

  get videoUrl() {
    return this.properties.videoUrl;
  }

  get hasMedia() {
    return this.imageEnabled && (!!this.image || !!this.properties.videoUrl);
  }

  get hideQuestionText() {
    return !!this.properties.HideQuestionText;
  }

  get frontInternalName() {
    const placeholder = 'Untitled';
    return this.hideQuestionText
      ? this.properties.InternalName || placeholder
      : this.properties.InternalName || this.text || placeholder;
  }

  constructor(props: Partial<QuizQuestion> = {}) {
    this.applyDefaults(props);
  }

  update(props: Partial<QuizQuestion>) {
    if (!props) {
      return;
    }

    this.applyDefaults({ ...this, ...props });
  }

  private applyDefaults(props: Partial<QuizQuestion>) {
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.options = mapArray(
      props.options,
      (o) => new QuizQuestionOption(o)
    ).sort(orderByAscending);

    this.properties = new QuizQuestionProperties(props.properties);
    this.propertyDefinitionId = Guid.valueOrUndefined(
      props.propertyDefinitionId
    );
  }

  getOption(id: Guid): QuizQuestionOption | undefined {
    const match = this.options.findIndex((o) => o.id.equals(id));
    return match === -1 ? undefined : this.options[match];
  }

  deleteOption(id: Guid) {
    this.options = this.options
      .filter((o) => !o.id.equals(id))
      .map((o, idx) => new QuizQuestionOption({ ...o, order: idx + 1 }));
  }

  updateOption(optionId: Guid, updatedOption: Partial<QuizQuestionOption>) {
    const option = this.getOption(optionId);
    if (option) {
      option.update({ ...updatedOption });
    }
  }

  addOption() {
    const lastOption = this.options[this.options.length - 1];
    const newOptionOrder = lastOption ? lastOption.order + 1 : 1;
    const newOption = QuizQuestionOption.new(newOptionOrder, false);

    this.options = [...this.options, newOption].map(
      (o, idx) => new QuizQuestionOption({ ...o, order: idx + 1 })
    );
  }

  hasLayout() {
    return !!this.properties?.Layout;
  }

  getFrontOrder(quizQuestions: QuizQuestion[]) {
    const frontOrder = quizQuestions.findIndex((q) => q.id.equals(this.id));
    return frontOrder === -1 ? undefined : frontOrder + 1;
  }

  getLabel = (
    questions: QuizQuestion[],
    options?: { hideOrderPrefix: boolean }
  ) => {
    const questionOrder = this.getFrontOrder(questions);
    const questionText =
      this.properties.InternalName || this.text || `Question ${questionOrder}`;
    const prefix = options?.hideOrderPrefix ? '' : `Q.${questionOrder} `;

    return `${prefix}${questionText}`;
  };

  getLayout(fallback: CardLayout) {
    return this.properties?.Layout || fallback;
  }

  static getOptionById(questions: QuizQuestion[], optionId: Guid) {
    return questions
      .flatMap((q) => q.options)
      .find((o) => Guid.equals(o.id, optionId));
  }

  static new(
    quizId: Guid,
    style: QuizAnswerStyles = QuizAnswerStyles.Single,
    order: number = 1
  ) {
    return new QuizQuestion({
      id: Guid.newGuid(),
      quizId,
      style,
      order,
      options: [
        QuizQuestionOption.new(1, true),
        QuizQuestionOption.new(2, false)
      ]
    });
  }
}
