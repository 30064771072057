import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { asBoolean } from '@komo-tech/core/utils/boolean';
import { utcDateOrUndefined } from '@komo-tech/core/utils/date';
import { immerable } from 'immer';

import { LeaderboardNameDisplayTypes } from '@/common/models/LeaderboardNameDisplayTypes';
import { SitePageProperties } from '@/common/models/pages/shared/SitePageProperties';
import { ISiteResolveButton } from '@/common/models/site/ISiteResolveButton';

import { SiteProperties } from '../../site/SiteProperties';
import { QuestionPoolModel } from './QuestionPoolModel';

export class TriviaGameProperties {
  [immerable] = true;
  ModalBackgroundColor?: string;

  get ModalHeaderImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.ModalHeaderImageJson);
  }

  ModalHeaderImageJson?: string;

  //Surfaces are any panels or containers that are not the main background
  //(i.e. Answers, Questions, etc.)
  SurfaceTextColor?: string;
  SurfaceBgColor?: string;
  SurfaceBdColor?: string;

  ProcessingWinnerBarBgColor?: string;
  ProcessingWinnerBarTextColor?: string;
  /**
   * The color of the brand for live data feeds.
   */
  readonly DefaultBrandColor = '#E31E34';
  BrandColor: string;

  /**
   * The color of the brand text for live data feeds.
   */
  readonly DefaultBrandTextColor = '#FFFFFF';
  BrandTextColor: string;

  BigScreenTextColor?: string;
  BigScreenBgColor?: string;

  readonly DefaultBigScreenWinnerText = "YOU'VE WON THE MAJOR PRIZE";
  BigScreenWinnerText: string;
  BigScreenWinnerNameDisplay: LeaderboardNameDisplayTypes;
  /**
   * Indicates if low bandwidth mode has been enabled for a game.
   */
  LowBandwidth: boolean = false;

  /**
   * Indicates if the minor prize should be disabled.
   */
  DisableMinorPrize: boolean = false;

  /**
   * The major prize heading.
   */
  MajorPrizeHeading: string;

  /**
   * The major prize description text.
   */
  MajorPrizeText: string;

  /**
   * The minor prize heading.
   */
  MinorPrizeHeading: string;

  /**
   * The minor prize description text.
   */
  MinorPrizeText: string;

  /**
   * The text to display when the user has finished. Default:
   * "Thanks for playing!"
   */
  FinishedText: string;

  /**
   * The action bar heading to show when the user has
   * finished (if no branded bar shown). Default: "Wait here".
   */
  FinishedActionBarHeading: string;

  /**
   * The action bar sub-heading to show when the user has
   * finished (if no branded bar shown). Default: "Winners shown
   * in a few minutes".
   */
  FinishedActionBarSubHeading: string;

  /**
   * The SEO meta image to be shown for shares.
   */
  MetaImage?: string;

  get MetaImageModel() {
    return ImageDataModel.fromJsonOrUrl(this.MetaImage);
  }
  /**
   * The SEO meta title to be shown for shares.
   */
  MetaTitle?: string;
  /**
   * The SEO meta description to be shown for shares.
   */
  MetaDescription?: string;
  /**
   * The heading to be shown on share boxes.
   */
  ShareHeading?: string;
  /**
   * The share text to be shown for pre-game shares.
   */
  PreGameShareText?: string;
  /**
   * The share text to be shown for post-game shares.
   */
  PostGameShareText?: string;
  /**
   * Indicates if sharing with Facebook is disabled.
   */
  FacebookSharingDisabled: boolean = false;
  /**
   * Indicates if sharing with Twitter is disabled.
   */
  TwitterSharingDisabled: boolean = false;
  /**
   * Indicates if sharing with Twitter is disabled.
   */
  WhatsappSharingDisabled: boolean = false;
  /**
   * Indicates if sharing with Messenger is disabled.
   */
  MessengerSharingDisabled: boolean = false;
  /**
   * Indicates if sharing with Instagram is disabled.
   */
  InstagramShareEnabled: boolean = false;
  /**
   * Indicates if sharing with Web (copy link) is disabled.
   */
  WebsiteSharingDisabled: boolean = false;

  /**
   * The link to use for CCPA compliance on the registration form.
   */
  CcpaLink?: string;

  readonly DefaultActionBarBackgroundColor = '#C21E30';
  ActionBarBackgroundColor: string;

  readonly DefaultActionBarTextColor = '#FFFFFF';
  ActionBarTextColor: string;

  readonly DefaultActionBarButtonBackgroundColor = '#22ABF0';
  ActionBarButtonBackgroundColor: string;

  readonly DefaultActionBarButtonTextColor = '#FFFFFF';
  ActionBarButtonTextColor: string;

  readonly DefaultModalHeaderTextColor = '#FFFFFF';
  ModalHeaderTextColor: string;
  readonly DefaultModalHeaderBackgroundColor = '#000000';
  ModalHeaderBackgroundColor: string;

  IsBigScreensEnabled?: boolean = false;
  AnswersRevealedAt?: Date;

  QuestionPoolEnabled?: boolean = false;
  QuestionPoolJson: string;

  QuestionShuffleEnabled?: boolean = false;
  QuestionFeedbackColor?: string = '#22abf0';
  QuestionTimerBarColor?: string = '#45c479';

  LeaderboardEnabled: boolean;
  static DefaultLeaderboardButtonText = 'View leaderboard';
  LeaderboardButtonText?: string;
  LeaderboardButtonColor?: string;
  LeaderboardButtonBgColor?: string;
  LeaderboardJson: string;

  static DefaultLeaderboardAnswersText: string =
    '<h3 style="text-align:center;">See how you stacked up!</strong></h3>';
  LeaderboardAnswersText?: string;
  LeaderboardAnswersTextStateJson?: string;

  constructor(props?: Partial<TriviaGameProperties>) {
    props = props || {};
    Object.assign(this, props);
    this.LowBandwidth = asBoolean(props.LowBandwidth);
    this.DisableMinorPrize = asBoolean(props.DisableMinorPrize);
    this.FacebookSharingDisabled = asBoolean(props.FacebookSharingDisabled);
    this.TwitterSharingDisabled = asBoolean(props.TwitterSharingDisabled);
    this.WhatsappSharingDisabled = asBoolean(props.WhatsappSharingDisabled);
    this.MessengerSharingDisabled = asBoolean(props.MessengerSharingDisabled);
    this.InstagramShareEnabled = asBoolean(props.InstagramShareEnabled);
    this.WebsiteSharingDisabled = asBoolean(props.WebsiteSharingDisabled);
    this.FinishedText = props.FinishedText || 'Thanks for playing!';
    this.IsBigScreensEnabled = asBoolean(props.IsBigScreensEnabled);
    this.QuestionPoolEnabled = asBoolean(props.QuestionPoolEnabled);
    this.AnswersRevealedAt = utcDateOrUndefined(props.AnswersRevealedAt);
    if (!this.ActionBarBackgroundColor)
      this.ActionBarBackgroundColor = this.DefaultActionBarBackgroundColor;

    if (!this.ActionBarTextColor)
      this.ActionBarTextColor = this.DefaultActionBarTextColor;

    if (!this.ActionBarButtonBackgroundColor)
      this.ActionBarButtonBackgroundColor =
        this.DefaultActionBarButtonBackgroundColor;

    if (!this.ActionBarButtonTextColor)
      this.ActionBarButtonTextColor = this.DefaultActionBarButtonTextColor;

    this.QuestionShuffleEnabled = asBoolean(props.QuestionShuffleEnabled);
    this.LeaderboardEnabled = asBoolean(props.LeaderboardEnabled);
  }

  getModalHeaderTextColor(site: { properties: SiteProperties }) {
    return (
      this.ModalHeaderTextColor ||
      site.properties.HeaderTextColor ||
      this.DefaultModalHeaderTextColor
    );
  }

  getModalHeaderBackgroundColor(site: { properties: SiteProperties }) {
    return (
      this.ModalHeaderBackgroundColor ||
      site.properties.HeaderBackgroundColor ||
      this.DefaultModalHeaderBackgroundColor
    );
  }

  getLeaderboardButton(
    site: ISiteResolveButton,
    properties: SitePageProperties
  ) {
    return site.resolveButton({
      properties,
      color: this.LeaderboardButtonColor,
      backgroundColor: this.LeaderboardButtonBgColor,
      text:
        this.LeaderboardButtonText ||
        TriviaGameProperties.DefaultLeaderboardButtonText
    });
  }

  get questionPoolModel(): QuestionPoolModel {
    return QuestionPoolModel.fromJson(this.QuestionPoolJson);
  }

  set questionPoolModel(value: QuestionPoolModel) {
    this.QuestionPoolJson = JSON.stringify(value);
  }
}
