import { ColorRGBA } from '@komo-tech/core/models/ColorRGBA';
import { Guid } from '@komo-tech/core/models/Guid';

import { PredictiveOptionPoints } from './PredictiveOptionPoints';

export interface RevealedAnswersSummary {
  revealed: number;
  total: number;
}

export interface AnsweredQuestionsSummary {
  answered: number;
  total: number;
}

export enum SegmentState {
  Locked = 'Locked',
  Live = 'Live',
  Completed = 'Completed'
}

export interface SelectedMultiChoiceOption {
  selectedOptionId: Guid;
  percentageSnapshot?: PredictiveOptionPoints[];
}

export interface SelectedSliderValue {
  selectedValue: number;
  points?: number;
  isCorrect: boolean;
}

export enum PredictiveQuestionStatus {
  None = 'None',
  Waiting = 'Waiting',
  Correct = 'Correct',
  Incorrect = 'Incorrect',
  Unanswered = 'Unanswered',
  NoAnswer = 'NoAnswer'
}

export enum PredictivePrizeEntryStatus {
  None = 'None',
  Entered = 'Entered',
  Winner = 'Winner',
  Loser = 'Loser'
}

export interface ColorPair {
  backgroundColor: ColorRGBA;
  color: ColorRGBA;
}
export interface PredictiveQuestionStatusColors {
  pending: ColorPair;
  correct: ColorPair;
  incorrect: ColorPair;
  invalid: ColorPair;
}
