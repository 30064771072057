export enum CardTypes {
  Checklist = 'Checklist',
  Countdown = 'Countdown',
  Carousel = 'Carousel',
  Embed = 'Embed',
  Fact = 'Fact',
  Image = 'Image',
  Leaderboard = 'Leaderboard',
  MemoryChallenge = 'MemoryChallenge',
  NumberGuess = 'NumberGuess',
  Quiz = 'Quiz',
  Predictive = 'Predictive',
  Canvas = 'Canvas',
  ScratchAndWin = 'ScratchAndWin',
  Upload = 'Upload',
  Trivia = 'Trivia',
  Video = 'Video',
  InstantWin = 'InstantWin',
  Picker = 'Picker',
  Swiper = 'Swiper',
  SpinToWin = 'SpinToWin',
  LiveSurvey = 'LiveSurvey',
  TreasureHunt = 'TreasureHunt'
}
