/**
 * The logic rules for quiz branching logic.
 */
export enum LogicRules {
  /**
   * The option will flow onto the next question in sequence.
   */
  NextQuestion = 'NextQuestion',
  /**
   * The option will trigger the quiz to end.
   */
  EndQuiz = 'EndQuiz',
  /**
   * The option will jump to the specified question.
   */
  JumpToQuestion = 'JumpToQuestion'
}
