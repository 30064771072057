import { ColorRGBA } from '@komo-tech/core/models/ColorRGBA';
import { Guid } from '@komo-tech/core/models/Guid';
import { asBoolean } from '@komo-tech/core/utils/boolean';
import { asModelOrFallback } from '@komo-tech/core/utils/string';

import { SitePageProperties } from '@/common/models/pages/shared/SitePageProperties';
import { SiteCardProperties } from '@/common/models/SiteCardProperties';
import {
  getPrimaryBackgroundColor,
  getPrimaryTextColor
} from '@/common/utils/ButtonFunctions';

import { QuizBridge } from './QuizBridge';

const colors = {
  white: '#ffffff',
  black: '#000000',
  gray1: '#f1f3f5',
  gray2: '#e9ecef',
  gray3: '#dee2e6',
  gray8: '#343a40',
  blue3: '#74c0fc'
};
export class QuizProperties implements SiteCardProperties {
  ButtonTextColor?: string;
  ButtonBackgroundColor?: string;

  HideAnswers?: boolean;
  HidePercentages?: boolean;

  CoverButtonText: string;
  CoverButtonTextColor?: ColorRGBA;
  CoverButtonBackgroundColor?: ColorRGBA;

  BridgeScreensJson?: string;

  NextButtonTextColor?: string;
  NextButtonBackgroundColor?: string;

  ReplayEnabled: boolean;
  ReplayButtonColor?: string;
  ReplayButtonText?: string;

  ViewAnswersEnabled: boolean;
  ViewAnswersButtonColor?: string;
  ViewAnswersButtonText?: string;

  QuestionHeaderBackgroundColor: string = colors.white;
  QuestionHeaderTextColor: string = colors.black;
  QuestionHeaderNavigationActiveColor: string = colors.gray8;
  QuestionHeaderNavigationInactiveColor: string = colors.gray3;
  QuestionBodyBackgroundColor: string = colors.white;
  QuestionBodyTextColor: string = colors.black;
  QuestionOptionBackgroundColor: string = colors.gray1;
  QuestionOptionFillColor: string = colors.blue3;
  QuestionOptionTextColor: string = colors.gray8;

  ResultHeaderBackgroundColor: string = colors.gray2;
  ResultHeaderTextColor: string = colors.black;
  ResultBodyBackgroundColor: string = colors.white;
  ResultBodyTextColor: string = colors.black;

  get replayButtonColor() {
    return this.ReplayButtonColor;
  }

  get viewAnswersButtonColor() {
    return this.ViewAnswersButtonColor;
  }

  constructor(props: Partial<QuizProperties> = {}) {
    Object.assign(this, props);
    this.HideAnswers = asBoolean(props?.HideAnswers);
    this.HidePercentages = asBoolean(props?.HidePercentages);
    this.ReplayEnabled = asBoolean(props.ReplayEnabled);
    this.CoverButtonTextColor = ColorRGBA.valueOrUndefined(
      props.CoverButtonTextColor
    );
    this.CoverButtonBackgroundColor = ColorRGBA.valueOrUndefined(
      props.CoverButtonBackgroundColor
    );
    this.ViewAnswersEnabled = asBoolean(props.ViewAnswersEnabled, true);
  }

  getBridgeScreens(): QuizBridge[] {
    const screens = asModelOrFallback(this.BridgeScreensJson);
    return screens?.length ? screens.map((x) => new QuizBridge(x)) : [];
  }

  addBridgeScreen(order: number) {
    const screens = this.getBridgeScreens();
    const newBridge = new QuizBridge({ order });
    this.setBridgeScreens([...screens, newBridge]);
    return newBridge;
  }

  getBridgeScreen(screenId: Guid): QuizBridge | undefined {
    const match = this.getBridgeScreens().findIndex((s) =>
      s.id.equals(screenId)
    );
    return match === -1 ? undefined : this.getBridgeScreens()[match];
  }

  updateBridgeScreen(screenId: Guid, partial: Partial<QuizBridge>) {
    const newScreens = this.getBridgeScreens().map((r) => {
      if (r.id.equals(screenId)) {
        return new QuizBridge({
          ...r,
          ...partial
        });
      }
      return r;
    });
    this.setBridgeScreens(newScreens);
  }

  setBridgeScreens(screens: QuizBridge[]) {
    const screensJson = JSON.stringify(screens);
    this.BridgeScreensJson = screensJson;
  }

  deleteBridgeScreen(screenId: Guid) {
    const newScreens = this.getBridgeScreens().filter(
      (s) => !s.id.equals(screenId)
    );
    this.setBridgeScreens(newScreens);
  }

  getCoverButtonTextColorAsString(properties: SitePageProperties) {
    if (this.CoverButtonTextColor) return this.CoverButtonTextColor.toString();

    return getPrimaryTextColor({
      properties
    });
  }

  getCoverButtonBackgroundColorAsString(properties: SitePageProperties) {
    if (this.CoverButtonBackgroundColor)
      return this.CoverButtonBackgroundColor.toString();

    return getPrimaryBackgroundColor({
      properties
    });
  }

  getNextScreenButtonProps(properties: SitePageProperties) {
    return {
      backgroundColor:
        this.NextButtonBackgroundColor || properties?.AccentBackgroundColor,
      color: this.NextButtonTextColor || properties?.AccentTextColor
    };
  }
}
