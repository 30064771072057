import { Guid } from '@komo-tech/core/models/Guid';
import { mapArray } from '@komo-tech/core/utils/array';

import { CardTypes } from '@/common/models/CardTypes';
import { SiteCard } from '@/common/models/SiteCard';
import { SiteCardHelper } from '@/common/models/SiteCardHelper';

import { disperseWheelWedgesForFront } from '../shared/_helpers';
import { SpinToWinProperties } from '../shared/SpinToWinProperties';
import { FrontSpinToWinResult } from './FrontSpinToWinResult';

export class FrontSpinToWin implements SiteCard {
  id: Guid;
  siteId: Guid;
  readonly type: CardTypes = CardTypes.SpinToWin;
  properties?: SpinToWinProperties;
  results: FrontSpinToWinResult[];

  constructor(props?: Partial<FrontSpinToWin>) {
    props = props || {};
    Object.assign(this, props);
    SiteCardHelper.applyDefaults(this, props);
    this.properties = new SpinToWinProperties(props.properties);
    this.results = mapArray(props.results, (x) => new FrontSpinToWinResult(x));
  }

  get WedgesForWheel() {
    return disperseWheelWedgesForFront(this.properties.WheelWedges);
  }
}
