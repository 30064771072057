export enum LiveGameplayStatuses {
  /**
   * The game has not started yet.
   */
  NotStarted = 'NotStarted',

  /**
   * The game play is underway.
   */
  Playing = 'Playing',

  /**
   * The game play time has finished and the winner is being processed.
   */
  Processing = 'Processing',

  /**
   * The Winner is announced and minor prizes also sent.
   */
  Prizes = 'Prizes',

  /**
   * The game experience has been archived and results are not longer available.
   */
  Archived = 'Archived'
}
