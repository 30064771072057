import { ColorRGBA } from '@komo-tech/core/models/ColorRGBA';
import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { asBoolean } from '@komo-tech/core/utils/boolean';
import { Theme } from '@komo-tech/ui/theme/types';

import { SitePageProperties } from '@/common/models/pages/shared/SitePageProperties';
import { SiteCardProperties } from '@/common/models/SiteCardProperties';

interface ButtonVariables {
  text: string;
  textColor: string;
  backgroundColor: string;
}

export class EmbedProperties implements SiteCardProperties {
  ButtonTextColor?: string;
  ButtonBackgroundColor?: string;

  ButtonText?: string = 'SHOW MORE';

  html: string;
  height: string;
  AmondoPropertiesJson?: string;

  // Modal styles
  BackgroundColor?: string = '#FFFFFF';
  BackgroundUrl?: string;

  get BackgroundImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.BackgroundUrl);
  }

  BackgroundImageEnabled?: boolean = false;
  BackgroundFilter?: ColorRGBA;
  BackgroundFilterEnabled?: boolean = false;

  // Modal Nav styles
  BrandImageUrl?: string;

  get BrandImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.BrandImageUrl);
  }

  HeaderBackgroundColor?: ColorRGBA;
  HeaderColor?: string = '#000000';
  AspectRatio?: string;
  UseAspectRatio?: boolean;

  constructor(props?: Partial<EmbedProperties>) {
    props = props || {};
    Object.assign(this, props);

    this.BackgroundImageEnabled = asBoolean(
      props.BackgroundImageEnabled,
      false
    );

    this.BackgroundFilterEnabled = asBoolean(
      props.BackgroundFilterEnabled,
      false
    );

    this.UseAspectRatio = asBoolean(props.UseAspectRatio, false);

    this.BackgroundFilter = ColorRGBA.valueOrUndefined(props.BackgroundFilter);

    this.HeaderBackgroundColor = ColorRGBA.valueOrDefault(
      props.HeaderBackgroundColor,
      ColorRGBA.fromHex('#FFFFFF')
    );
  }

  getAmondoProperties() {
    if (!this.AmondoPropertiesJson) return new AmondoPropertiesModel();
    try {
      return new AmondoPropertiesModel(JSON.parse(this.AmondoPropertiesJson));
    } catch (error) {
      console.error(error);
      return new AmondoPropertiesModel();
    }
  }

  setAmondoProperties(model: AmondoPropertiesModel) {
    this.AmondoPropertiesJson = JSON.stringify(model);
  }

  getButtonTextColor(properties: SitePageProperties, theme: Theme) {
    const { white } = theme.other.colors;
    const { AccentTextColor } = properties;
    const { ButtonTextColor } = this;
    return ButtonTextColor || AccentTextColor || white;
  }

  getButtonBackgroundTextColor(properties: SitePageProperties, theme: Theme) {
    const { black } = theme.other.colors;
    const { AccentBackgroundColor } = properties;
    const { ButtonBackgroundColor } = this;
    return ButtonBackgroundColor || AccentBackgroundColor || black;
  }

  getButtonVariables(
    properties: SitePageProperties,
    theme: Theme
  ): ButtonVariables {
    return {
      text: this.ButtonText,
      textColor: this.getButtonTextColor(properties, theme),
      backgroundColor: this.getButtonBackgroundTextColor(properties, theme)
    };
  }

  getModalBackgroundProps() {
    return {
      image: this.BackgroundImageEnabled ? this.BackgroundImage : undefined,
      color: this.BackgroundColor,
      filterRgb: this.BackgroundFilterEnabled
        ? this.BackgroundFilter?.toString()
        : undefined
    };
  }
}

const defaultOptions =
  '{\n' +
  '    "layout": {\n' +
  '        "content": true,\n' +
  '        "cover": false,\n' +
  '        "date": false,\n' +
  '        "location": false,\n' +
  '        "logo": false,\n' +
  '        "subtitle": false,\n' +
  '        "title": false\n' +
  '    },\n' +
  '    "theme": {\n' +
  '        "accentColour": "#FFF200",\n' +
  '        "background": "transparent",\n' +
  '        "foreground": "#FFFFFF"\n' +
  '    },\n' +
  '    "peek": false\n' +
  '}';

export class AmondoPropertiesModel {
  heightEnabled: boolean;
  imprintId: string;
  options: string;
  constructor(props?: Partial<AmondoPropertiesModel>) {
    props = props || {};
    Object.assign(this, props);
    this.heightEnabled = asBoolean(props.heightEnabled, false);
    this.options = props.options || defaultOptions;
  }

  static defaultButtonText = 'SHOW MORE';
}
