/**
 * All of the types of quiz question answer styles.
 */
export enum QuizAnswerStyles {
  /**
   * The user must select a single correct answer.
   */
  Single = 'Single',
  /**
   * The user must select all (multiple) answers.
   */
  All = 'All',
  /**
   * A survey/poll question where there are no correct answers.
   */
  Poll = 'Poll'
}
