import { Guid } from '@komo-tech/core/models/Guid';
import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { mapArray } from '@komo-tech/core/utils/array';
import { asBoolean } from '@komo-tech/core/utils/boolean';
import { asNumber, asNumberOrUndefined } from '@komo-tech/core/utils/number';
import isNumber from 'lodash/isNumber';

import { ExternalLinkButtonModel } from '@/common/models/ExternalLinkButtonModel';

import { QuizResultProperties } from './QuizResultProperties';

export class QuizResult {
  id: Guid;
  headerText: string = ''; // the title
  /**
   * HTML of the description
   */
  descriptionText: string = '';
  imageUrl: string = '';

  get image() {
    return ImageDataModel.fromJsonOrUrl(this.imageUrl);
  }

  imageEnabled: boolean;
  dataCaptureEnabled: boolean;
  minCorrectAnswers?: number;
  maxCorrectAnswers?: number;
  buttons: ExternalLinkButtonModel[];

  properties: QuizResultProperties;

  get screenProps() {
    return {
      description: this.descriptionText,
      image: this.imageEnabled ? this.image : undefined,
      title: this.headerText
    };
  }

  constructor(partial: Partial<QuizResult> = {}) {
    Object.assign(this, partial);
    this.id = Guid.valueOrNew(partial.id);
    this.imageEnabled = asBoolean(partial.imageEnabled, true);
    this.dataCaptureEnabled = asBoolean(partial.dataCaptureEnabled, true);
    this.minCorrectAnswers = asNumberOrUndefined(partial.minCorrectAnswers);
    this.maxCorrectAnswers = asNumberOrUndefined(partial.maxCorrectAnswers);
    this.buttons = mapArray(
      partial.buttons,
      (b) => new ExternalLinkButtonModel(b)
    );
    this.properties = new QuizResultProperties(partial.properties);
  }

  hasResultRange(): boolean {
    return (
      isNumber(this?.minCorrectAnswers) && isNumber(this?.maxCorrectAnswers)
    );
  }

  hasContent(): boolean {
    return (
      !!this.headerText ||
      !!this.descriptionText ||
      (this.imageEnabled && !!this.image)
    );
  }

  static GetTriggerRangeLabel(result: QuizResult, placeholder = 'Set range') {
    if (isNaN(result?.minCorrectAnswers) || isNaN(result?.maxCorrectAnswers)) {
      return placeholder;
    }

    if (
      asNumber(result?.minCorrectAnswers) ===
      asNumber(result?.maxCorrectAnswers)
    ) {
      return `${result?.minCorrectAnswers} correct`;
    }

    return `${result?.minCorrectAnswers} - ${result?.maxCorrectAnswers} correct`;
  }
}
