import { CardDescriptor } from '@/common/models/CardDescriptor';
import { CardDescriptorProperties } from '@/common/models/CardDescriptorProperties';
import { ImageBase } from '@/common/models/image/shared';
import { SitePageProperties } from '@/common/models/pages/shared/SitePageProperties';
import {
  getPrimaryBackgroundColor,
  getPrimaryTextColor
} from '@/common/utils/ButtonFunctions';

export class AdminImage extends ImageBase<AdminImage> {
  constructor(props?: Partial<AdminImage>) {
    super(props);
  }

  getCoverProps(
    properties: SitePageProperties,
    cardDescriptor: CardDescriptor
  ) {
    const hasButtonUrl = !!this.properties.ButtonUrl;
    const showButton =
      this.properties.ButtonEnabled &&
      (cardDescriptor.hasCompetition || hasButtonUrl);
    return {
      image: this.image,
      hideButton: !showButton,
      buttonText: this.getButtonText(cardDescriptor),
      buttonBackgroundColor: getPrimaryBackgroundColor({
        card: this,
        properties
      }),
      buttonTextColor: getPrimaryTextColor({ card: this, properties })
    };
  }

  getButtonText(cardDescriptor: CardDescriptor) {
    if (cardDescriptor.hasCompetition) {
      return (
        cardDescriptor.competition.form.properties.EntryButtonText ||
        this.properties.ButtonText ||
        CardDescriptorProperties.DefaultCompetitionButtonText
      );
    }
    return this.properties.ButtonText || ImageBase.DefaultButtonText;
  }
}
