import { Guid } from '@komo-tech/core/models/Guid';
import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { asBoolean } from '@komo-tech/core/utils/boolean';

export class TriviaActionScreenProperties {
  callToAction: string;
  expertImage?: string;

  get ExpertImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.expertImage);
  }

  expertScore?: number;
  fieldId?: Guid;
  logoUrl?: string;

  get LogoImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.logoUrl);
  }

  questionId?: Guid;
  secondaryColor?: string;
  header?: string;
  subHeader?: string;
  url?: string;
  callToActionContentEnabled: boolean;
  brandLogoEnabled: boolean;

  constructor(props?: Partial<TriviaActionScreenProperties>) {
    props = props || {};
    Object.assign(this, props);
    this.callToActionContentEnabled = asBoolean(
      props.callToActionContentEnabled,
      true
    );
    this.brandLogoEnabled = asBoolean(props.brandLogoEnabled, true);
  }
}
