import { Guid } from '@komo-tech/core/models/Guid';

import { CardTypes } from '@/common/models/CardTypes';
import { SiteCard } from '@/common/models/SiteCard';
import { SiteCardHelper } from '@/common/models/SiteCardHelper';

import { LiveSurveyProperties } from '../shared/LiveSurveyProperties';

export class FrontLiveSurvey implements SiteCard {
  id: Guid;
  siteId: Guid;
  readonly type: CardTypes = CardTypes.LiveSurvey;
  properties: LiveSurveyProperties;
  updatedAt: Date;
  constructor(props?: Partial<FrontLiveSurvey>) {
    props = props || {};
    Object.assign(this, props);
    SiteCardHelper.applyDefaults(this, props);
    this.properties = new LiveSurveyProperties(props?.properties);
  }
}
