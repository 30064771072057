import { Guid } from '@komo-tech/core/models/Guid';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { FrontAnyCard } from '@/common/models/AnyCard';
import { CardTypes } from '@/common/models/CardTypes';

import { PublicCardActions as Actions } from '../_actions';
import { PublicCardsCacheKeys as CacheKeys } from '../_cacheKeys';

interface Options extends UseQueryOptions<FrontAnyCard> {
  cardId: Guid;
  cardType: CardTypes;
}
export const useFrontTypedCard = ({
  cardId,
  cardType,
  refetchOnMount = false,
  refetchOnWindowFocus = false,
  ...rest
}: Options) => {
  const {
    data,
    isLoading,
    isInitialLoading,
    isError,
    error,
    refetch: refetchAsync
  } = useQuery(
    CacheKeys.typed(cardId),
    () => Actions.getTypedAsync(cardId, cardType),
    {
      refetchOnMount,
      refetchOnWindowFocus,
      ...rest
    }
  );

  return [
    data,
    { error, refetchAsync, isError, isLoading: isLoading || isInitialLoading }
  ] as const;
};
