import { Guid } from '@komo-tech/core/models/Guid';
import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { asBoolean } from '@komo-tech/core/utils/boolean';
import { asNumberOrUndefined } from '@komo-tech/core/utils/number';
import { immerable } from 'immer';

import { CardTypes } from '@/common/models/CardTypes';
import { SitePageProperties } from '@/common/models/pages/shared/SitePageProperties';
import { SiteCard } from '@/common/models/SiteCard';
import { SiteCardHelper } from '@/common/models/SiteCardHelper';
import {
  getPrimaryBackgroundColor,
  getPrimaryTextColor
} from '@/common/utils/ButtonFunctions';

import { NumberGuessProperties } from './NumberGuessProperties';

export class NumberGuess implements SiteCard {
  [immerable] = true;
  id: Guid;
  siteId: Guid;
  readonly type: CardTypes = CardTypes.NumberGuess;
  name: string;
  heading: string = '';
  imageUrl: string = '';

  get image(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.imageUrl);
  }

  totalVotes: number = 0;
  showStatsEnabled: boolean;
  minNumber?: number;
  maxNumber?: number;
  defaultNumber?: number;
  answer?: number;
  properties: NumberGuessProperties;

  constructor(props?: Partial<NumberGuess>) {
    props = props || {};
    Object.assign(this, props);
    SiteCardHelper.applyDefaults(this, props);
    this.showStatsEnabled = asBoolean(props.showStatsEnabled, true);
    this.properties = new NumberGuessProperties(props.properties);
    this.answer = asNumberOrUndefined(props.answer);
  }

  calculateValueWithPrecision(value: number, precision?: number) {
    return (
      value /
      Math.pow(
        10,
        precision !== undefined ? precision : this.properties.Decimals
      )
    );
  }

  getButtonBackgroundColor(properties: SitePageProperties) {
    return getPrimaryBackgroundColor({
      card: this,
      properties
    });
  }

  getButtonTextColor(properties: SitePageProperties) {
    return getPrimaryTextColor({
      card: this,
      properties
    });
  }

  getColors(properties: SitePageProperties) {
    const buttonColor = this.getButtonBackgroundColor(properties);
    const buttonTextColor = this.getButtonTextColor(properties);

    return { buttonColor, buttonTextColor };
  }

  getConfirmButtonProps(properties: SitePageProperties) {
    return {
      ...this.getColors(properties),
      buttonText:
        this.properties.ButtonText || this.properties.DefaultButtonText
    };
  }

  getMinMax() {
    return {
      min: this.calculateValueWithPrecision(this.minNumber),
      max: this.calculateValueWithPrecision(this.maxNumber)
    };
  }

  getImageAndDataProps() {
    return {
      ...this.getMinMax(),
      image: this.image,
      answer: this.calculateValueWithPrecision(this.answer),
      answerSuffix: this.properties.AnswerSuffix
    };
  }

  scaleNumberAndRoundToInteger(value: number) {
    return Math.round(value * Math.pow(10, this.properties.Decimals));
  }
}
