import { OptionModel } from '@komo-tech/core/models/OptionModel';

import { SiteCard } from '@/common/models/SiteCard';

import { BasePicker } from '../shared/BasePicker';

export class AdminPicker extends BasePicker implements SiteCard {
  get tagsOptionModels(): OptionModel<string>[] {
    return this.tags.map((x) => ({ value: x.id.toString(), label: x.name }));
  }

  constructor(props: Partial<AdminPicker> = {}) {
    super(props);
  }
}
