import { Guid } from '@komo-tech/core/models/Guid';
import {
  DirectedEdge,
  DirectedGraph,
  hasCycles
} from '@komo-tech/core/utils/graph';
import isNil from 'lodash/isNil';

import { LogicRules } from '@/common/models/quiz/LogicRules';

import { QuizQuestion } from './QuizQuestion';

/**
 * Check if the list of questions has any non-standard logic configured.
 * This will return true if there are any options with "JumpTo" or "EndQuiz" configured.
 * @param questions The list of questions in the quiz.
 */
export const hasLogicConfigured = (questions: QuizQuestion[]): boolean => {
  if (questions.some(questionHasLogicConfigured)) {
    return true;
  }

  // todo - check if last Q has "end quiz" case?
  return false;
};

/**
 * Check if the question has any non-standard logic configured.
 * This will return true if there are any options with "JumpTo" or "EndQuiz" configured.
 * @param question
 */
export const questionHasLogicConfigured = (question: QuizQuestion): boolean => {
  return question.options.some((o) => o.logicRule !== LogicRules.NextQuestion);
};

/**
 * Check if any "Jump To Question" options have been configured in the given question.
 * @param question
 */
export const anyJumps = (question: QuizQuestion): boolean => {
  return question.options.some(
    (q) => q.logicRule === LogicRules.JumpToQuestion
  );
};

const END_QUIZ_NODE: string = '_ENDQUIZ';

/**
 * Check if the set of questions has a cycle, based on the configured logic.
 * @param questions
 */
export const hasLogicCycle = (questions: QuizQuestion[]): boolean => {
  if (isNil(questions) || questions.length == 0) {
    return false;
  }

  const edges: DirectedEdge[] = questions.flatMap((q, qi) =>
    q.options.flatMap((o) => {
      const source = o.id.toString();
      if (
        o.logicRule === LogicRules.EndQuiz ||
        (o.logicRule === LogicRules.NextQuestion && qi === questions.length - 1)
      ) {
        return [{ source, dest: END_QUIZ_NODE }];
      }

      let nextQuestion: QuizQuestion;
      if (o.logicRule === LogicRules.JumpToQuestion) {
        nextQuestion = questions.find((x) =>
          Guid.equals(x.id, o.jumpToQuestionId)
        );
      } else if (o.logicRule === LogicRules.NextQuestion) {
        nextQuestion = questions[qi + 1];
      }

      if (!nextQuestion) {
        return [];
      }

      return nextQuestion.options.map((x) => {
        return { source, dest: x.id.toString() };
      });
    })
  );

  const graph = new DirectedGraph({ edges });

  return hasCycles(graph);
};
