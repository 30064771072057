import { classFromJsonOrFallback } from '@komo-tech/core/utils/type';
import { immerable } from 'immer';

const colors = {
  black: '#000000',
  white: '#FFFFFF'
};
export class TreasureHuntDrawerProperties {
  [immerable] = true;

  headerTextColor: string = colors.black;
  headerBackgroundColor: string = colors.white;
  bodyTextColor: string = colors.black;
  bodyBackgroundColor: string = colors.white;

  constructor(props?: Partial<TreasureHuntDrawerProperties>) {
    props = props || {};
    Object.assign(this, props);
  }

  public static fromJsonOrNew(
    value: string,
    fallback?: Partial<TreasureHuntDrawerProperties>
  ) {
    return classFromJsonOrFallback(
      TreasureHuntDrawerProperties,
      value,
      new TreasureHuntDrawerProperties(fallback)
    );
  }
}
