export enum TriviaGameScreenTypes {
  Rules = 'Rules',
  Lobby = 'Lobby',
  QuizQuestion = 'QuizQuestion',
  Finished = 'Finished',
  Processing = 'Processing',
  Winner = 'Winner',
  Prize = 'Prize',
  Answers = 'Answers'
}
