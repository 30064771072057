import { immerable } from 'immer';

import { FrontAnyCard } from '@/common/models/AnyCard';
import { CardDescriptor } from '@/common/models/CardDescriptor';
import { FrontCardFactory } from '@/common/models/CardFactory';

export class CardViewModel {
  [immerable] = true;
  cardDescriptor: CardDescriptor;
  typedFrontCard: FrontAnyCard;

  constructor(props?: Partial<CardViewModel>) {
    props = props || {};
    Object.assign(this, props);
    this.cardDescriptor = new CardDescriptor(props.cardDescriptor);
    this.typedFrontCard = FrontCardFactory.map(
      props.cardDescriptor.type,
      props.typedFrontCard
    );
  }
}
