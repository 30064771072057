import { Guid } from '@komo-tech/core/models/Guid';
import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';

import { ITriviaGameScreen } from './ITriviaGameScreen';
import { TriviaGameScreenTypes } from './TriviaGameScreenTypes';

export class TriviaGameImageScreen implements ITriviaGameScreen {
  id: Guid;
  type: TriviaGameScreenTypes;
  contentUrl: string;

  get CoverImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.contentUrl);
  }

  constructor(props?: Partial<TriviaGameImageScreen>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
  }
}
