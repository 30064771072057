import { Guid } from '@komo-tech/core/models/Guid';

import { SiteCard } from '@/common/models/SiteCard';

export const SiteCardHelper = {
  applyDefaults: (card: SiteCard, props: Partial<SiteCard>) => {
    card.id = Guid.valueOrEmpty(props.id);
    card.siteId = Guid.valueOrEmpty(props.siteId);
  }
};
