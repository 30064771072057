import { Guid } from '@komo-tech/core/models/Guid';
import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { mapArray } from '@komo-tech/core/utils/array';

import { CardTypes } from '@/common/models/CardTypes';
import { SiteCard } from '@/common/models/SiteCard';
import { SiteCardHelper } from '@/common/models/SiteCardHelper';

import { MemoryChallengeProperties } from '../shared/MemoryChallengeProperties';
import { FrontMemoryTile } from './FrontMemoryTile';

export class FrontMemoryChallenge implements SiteCard {
  id: Guid;
  siteId: Guid;
  readonly type: CardTypes = CardTypes.MemoryChallenge;
  properties: MemoryChallengeProperties;
  name: string;
  memoryTiles: FrontMemoryTile[];

  constructor(props?: Partial<FrontMemoryChallenge>) {
    props = props || {};
    Object.assign(this, props);
    SiteCardHelper.applyDefaults(this, props);
    this.properties = new MemoryChallengeProperties(props?.properties);
    this.memoryTiles = mapArray(
      props.memoryTiles,
      (x) => new FrontMemoryTile(x)
    );
  }

  get backOfAllTilesImage() {
    return (
      this.properties.BackTileImage ||
      ImageDataModel.fromJsonOrUrl(
        '/assets/images/back-add-image-placeholder.png'
      )
    );
  }

  get modalHeaderProps() {
    return {
      headerColor: this.properties.HeaderTextColor,
      headerBackgroundColor: this.properties.HeaderBackgroundColor,
      headerImage: this.properties.HeaderImageModel
    };
  }

  get modalBackgroundProps() {
    return {
      backgroundImage: this.properties.BackgroundImage,
      backgroundColor: this.properties.BackgroundColor
    };
  }

  get timerProps() {
    return {
      numberOfSeconds: this.properties.SecondsGameplay,
      backgroundColor: this.properties.TimerBackgroundColor,
      textColor: this.properties.TimerTextColor
    };
  }

  get scoringProps() {
    return {
      backgroundColor: this.properties.ScoringBackgroundColor,
      textColor: this.properties.ScoringTextColor,
      scoringEnabled: !this.properties.LegacyScoring,
      progressBarEnabled: !this.properties.ScoringProgressBarDisabled,
      progressBarColor:
        this.properties.ScoringProgressBarColor ||
        this.properties.ScoringTextColor
    };
  }

  get gridImages() {
    return this.memoryTiles
      .map((x) => x.tileImage)
      .concat(this.backOfAllTilesImage);
  }

  get scoreConfig() {
    return this.properties.ScoreConfig;
  }

  getMatchScore(currentStreak: number) {
    return this.properties.getMatchScore(currentStreak);
  }
}
