import { Guid } from '@komo-tech/core/models/Guid';
import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';

export class LiveQuizAnswer {
  public id: Guid;
  public answerText: string;
  public answerImageUrl: string;

  get AnswerImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.answerImageUrl);
  }

  constructor(props?: Partial<LiveQuizAnswer>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrEmpty(props.id);
  }
}
