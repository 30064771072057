import { WheelWedge } from './SpinToWinProperties';

export const disperseWheelWedgesForFront = (
  wedges: WheelWedge[]
): WheelWedge[] => {
  wedges = wedges.sort((a, b) => b.repeatNumber - a.repeatNumber);
  const totalWedges = wedges.reduce((totalWedges, wedge) => {
    return totalWedges + wedge.repeatNumber;
  }, 0);
  const distributionItems = wedges.reduce((wedgeArray, wedge) => {
    const newArray = {
      item: wedge,
      count: wedge.repeatNumber,
      frequency: totalWedges / wedge.repeatNumber,
      priority: Math.floor(totalWedges / wedge.repeatNumber)
    };
    return [...wedgeArray, newArray];
  }, []);

  let prev: distributionItem = undefined;
  const itemArray = [];
  while (distributionItems.length > 0) {
    const item = distributionItems.shift();
    // add item with lowest priority number
    itemArray.push(item.item);
    item.count -= 1;
    item.priority += item.frequency;
    if (!!prev) {
      // insert previous item back into list in correct position
      const index = distributionItems.findIndex(
        (i) =>
          (i.priority == prev.priority && i.frequency > prev.frequency) ||
          i.priority > prev.priority
      );
      if (index > -1) {
        distributionItems.splice(index, 0, prev);
      } else {
        distributionItems.push(prev);
      }
      prev = undefined;
    }
    if (item.count > 0) {
      // track item as previous if it still has more items to insert
      prev = item;
    }
  }
  if (!!prev) {
    for (let i = 0; i < prev.count; i++) {
      itemArray.push(prev.item);
    }
  }
  return itemArray;
};

type distributionItem = {
  item: WheelWedge;
  count: number;
  frequency: number;
  priority: number;
};
