import { HeightWidth } from '@komo-tech/core/models/HeightWidth';
import { asBoolean } from '@komo-tech/core/utils/boolean';

import { SiteCardProperties } from '@/common/models/SiteCardProperties';

export enum VotesDisplayTypes {
  AlwaysShow = 'AlwaysShow',
  NeverShow = 'NeverShow',
  ShowAfterConfirmation = 'ShowAfterConfirmation'
}

export class CarouselProperties implements SiteCardProperties {
  ButtonTextColor?: string;
  ButtonBackgroundColor?: string;
  DontShuffleItems?: boolean;

  VotingEnabled?: boolean;
  VoteButtonLabel?: string = 'Vote';
  VoteButtonTextColor?: string = '#FCFDFD';
  VoteButtonBackgroundColor?: string = '#000000';
  VotesDisplay?: VotesDisplayTypes = VotesDisplayTypes.AlwaysShow;
  HideItemHeaderText?: boolean;
  HideItemSubHeaderText?: boolean;

  AspectRatioJson?: string;

  constructor(props?: Partial<CarouselProperties>) {
    props = props || {};
    Object.assign(this, props);
    this.DontShuffleItems = asBoolean(props.DontShuffleItems);
    this.VotingEnabled = asBoolean(props.VotingEnabled);
    this.HideItemHeaderText = asBoolean(props.HideItemHeaderText);
    this.HideItemSubHeaderText = asBoolean(props.HideItemSubHeaderText);
    this.VoteButtonLabel = props.VoteButtonLabel || 'Vote';
  }

  get aspectRatio(): HeightWidth | undefined {
    try {
      return JSON.parse(this.AspectRatioJson);
    } catch {
      return undefined;
    }
  }
}
