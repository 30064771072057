import { HeightWidth } from '@komo-tech/core/models/HeightWidth';
import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { asBoolean } from '@komo-tech/core/utils/boolean';

export class SwiperOptionProperties {
  ImageJson: string;

  get Image() {
    return ImageDataModel.fromJsonOrUrl(this.ImageJson);
  }

  Title?: string;
  SubTitle?: string;
  IsCorrect?: boolean;

  static AspectRatio: HeightWidth = {
    height: 1,
    width: 1
  };

  constructor(props?: Partial<SwiperOptionProperties>) {
    props = props || {};
    Object.assign(this, props);
    if (props.IsCorrect) {
      this.IsCorrect = asBoolean(props.IsCorrect);
    }
  }
}
