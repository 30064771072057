export enum TriviaGameStatuses {
  /**
   * The games are still in development and not ready to be
   * released to users.
   *
   * Games of this status should never be available from the game API.
   */
  Draft = 'Draft',

  /**
   * The game is live and can be registered for after the startedAt
   * time, this also includes games that are in progress.
   */
  Live = 'Live',

  /**
   * The game has been archived and is no longer on the game
   * selection page.
   */
  Archived = 'Archived'
}
