import { Dispatch, MutableRefObject, SetStateAction, useMemo } from 'react';

import { useConstantCallback } from './useConstant';
import { useStateWithRef } from './useStateWithRef';

export const useFlag = (initial?: boolean) => {
  const [value, set, valueRef] = useStateWithRef(initial || false);

  const setTrue = useConstantCallback(() => set(true));
  const setFalse = useConstantCallback(() => set(false));
  const toggle = useConstantCallback(() => set((x) => !x));

  return useMemo<UseFlagState>(
    () => ({
      value,
      valueRef,
      set,
      setTrue,
      setFalse,
      toggle
    }),
    [value]
  );
};

export interface UseFlagState {
  value: boolean;
  valueRef: MutableRefObject<boolean>;
  set: Dispatch<SetStateAction<boolean>>;
  setTrue: () => void;
  setFalse: () => void;
  toggle: () => void;
}
