import { Guid } from '@komo-tech/core/models/Guid';
import { asNumber } from '@komo-tech/core/utils/number';

export enum ButtonLinkVariants {
  ExternalLink = 'ExternalLink',
  ScrollToCard = 'ScrollToCard',
  ProfileDrawer = 'ProfileDrawer'
}

export class ExternalLinkButtonModel {
  id: Guid;
  text?: string;
  url?: string;
  order: number;
  textColor?: string;
  backgroundColor?: string;
  borderColor?: string;
  toHub?: boolean;
  cardId?: Guid;
  linkVariant: ButtonLinkVariants = ButtonLinkVariants.ExternalLink;

  get isValid() {
    if (!this.text) return false;
    if (!!this.toHub) return true;
    switch (this.linkVariant) {
      case ButtonLinkVariants.ProfileDrawer:
        return true;
      case ButtonLinkVariants.ScrollToCard:
        return !!this.cardId;
      case ButtonLinkVariants.ExternalLink:
        return !!this.url;
      default:
        return false;
    }
  }

  get frontUrl() {
    if (!this.url) {
      return null;
    }
    if (this.url.indexOf('://') === -1) {
      return `https://${this.url}`;
    }
    return this.url;
  }

  resolveColors() {
    const hasBg = !!this.backgroundColor;
    const textColor = this.textColor || '#000000';
    if (!hasBg) {
      return {
        bgColor: 'transparent',
        color: textColor,
        borderColor: textColor
      };
    }
    return {
      bgColor: this.backgroundColor,
      color: textColor,
      borderColor: this.borderColor || textColor
    };
  }

  constructor(props?: Partial<ExternalLinkButtonModel>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.order = asNumber(props.order, 1);
    this.cardId = Guid.valueOrUndefined(props.cardId);
  }
}
