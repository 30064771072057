import isNil from 'lodash/isNil';

export enum CanvasTextAlignment {
  Left = 'left',
  Center = 'center',
  Right = 'right'
}

export type CanvasInteractiveTextType = 'readOnly' | 'interactive';
export interface CanvasTextArray {
  width: number;
  text: string;
}
export class CanvasTextData {
  text: string;
  fontSize?: number;
  fontFamily?: string;
  align?: CanvasTextAlignment;
  color?: string;
  strokeColor?: string;
  interactiveType: CanvasInteractiveTextType;
  interactiveLabel?: string;
  interactiveMaxFontSize?: number;

  static defaultFontSize: number = 24;
  static defaultFontFamily: string = "'Roboto', sans-serif";
  static defaultAlignment = CanvasTextAlignment.Left;
  static defaultColor: string = '#000000';
  static defaultInteractiveType: CanvasInteractiveTextType = 'readOnly';

  constructor(props?: Partial<CanvasTextData>) {
    props = props || {};
    Object.assign(this, props);
    if (isNil(this.fontSize)) this.fontSize = CanvasTextData.defaultFontSize;
    this.fontFamily = props.fontFamily || CanvasTextData.defaultFontFamily;
    this.align = props.align || CanvasTextData.defaultAlignment;
    this.color = props.color || CanvasTextData.defaultColor;
    if (
      this.interactiveType !== 'readOnly' &&
      this.interactiveType !== 'interactive'
    ) {
      this.interactiveType = CanvasTextData.defaultInteractiveType;
    }

    if (this.interactiveType === 'readOnly') {
      this.interactiveMaxFontSize = undefined;
    }
  }

  getDisplayedCanvasText(canvasTextArray: CanvasTextArray[]) {
    let canvasText = canvasTextArray.map((x) => x.text).join('');
    if (!this.text) return canvasText;
    for (let i = 0; i < this.text.length; ++i) {
      const canvasChar = canvasText[i];
      const textChar = this.text[i];
      if (canvasChar !== textChar && hasWhitespaceOrNewLine(textChar)) {
        const tempString =
          canvasText.slice(0, i) + textChar + canvasText.slice(i);
        canvasText = tempString;
      }
    }
    return canvasText;
  }

  shouldProcessFitSizeToText() {
    return (
      this.interactiveType !== 'interactive' || !this.interactiveMaxFontSize
    );
  }

  shouldProcessFitTextToSize() {
    return this.interactiveType === 'interactive' && this.interactiveMaxFontSize
      ? true
      : false;
  }
}

const hasWhitespaceOrNewLine = (text: string) => text === '\n' || text === ' ';
