import { Guid } from '@komo-tech/core/models/Guid';
import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';

export class ChecklistOption {
  id: Guid;
  checklistId: Guid;
  text: string = '';
  imageUrl: string;

  get image(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.imageUrl);
  }

  order: number = 1;
  points: number = 1;

  constructor(props?: Partial<ChecklistOption>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.checklistId = Guid.valueOrUndefined(props.checklistId);
  }
}
