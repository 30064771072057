import { Guid } from '@komo-tech/core/models/Guid';
import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { mapArray } from '@komo-tech/core/utils/array';
import { asBoolean } from '@komo-tech/core/utils/boolean';
import { asNumber } from '@komo-tech/core/utils/number';

import { ButtonDataModel } from '@/common/models/ButtonDataModel';
import { CardResult } from '@/common/models/card-result/CardResult';
import { CardTypes } from '@/common/models/CardTypes';
import { SiteCard } from '@/common/models/SiteCard';
import { SiteCardHelper } from '@/common/models/SiteCardHelper';

import { disperseWheelWedgesForFront } from '../shared/_helpers';
import { SpinToWinProperties, WheelWedge } from '../shared/SpinToWinProperties';

export class AdminSpinToWin implements SiteCard {
  id: Guid;
  siteId: Guid;
  readonly type: CardTypes = CardTypes.SpinToWin;
  properties: SpinToWinProperties;

  constructor(partial?: Partial<AdminSpinToWin>) {
    partial = partial || {};
    Object.assign(this, partial);
    SiteCardHelper.applyDefaults(this, partial);
    this.properties = new SpinToWinProperties(partial?.properties);
  }

  get WedgesForWheel() {
    return disperseWheelWedgesForFront(this.properties.WheelWedges);
  }

  getModalBackgroundImagePath() {
    return `site/${this.siteId}/card/${this.id}/modal-background`;
  }

  getSpinnerImagePath() {
    return `site/${this.siteId}/card/${this.id}/spinner-image`;
  }

  getWedgeData(
    results: CardResult[],
    hideExhausted?: boolean
  ): WedgeDataWithResult[] {
    const wedgeData = this.properties.WheelWedges;
    if (!results?.length || !wedgeData || !wedgeData.length) {
      return [];
    }

    const wedgeResults: WedgeDataWithResult[] = [];

    results.forEach((r) => {
      const wedge = wedgeData.find((w) => w.resultId.equals(r.id));
      const resultData = r.properties.getProbability();
      const viewProperties = r.properties.getLegacyContent();

      let skip = false;
      if (
        hideExhausted &&
        !resultData.isUnlimited &&
        resultData.maxWinners > 0
      ) {
        const remaining = Math.max(resultData.maxWinners - r.recipients, 0);
        skip = remaining === 0;
      }

      if (!skip) {
        wedgeResults.push(
          new WedgeDataWithResult({
            ...wedge,
            result: r,
            name: r.name,
            isUnlimited: resultData.isUnlimited,
            recipients: r.recipients,
            resultTitle: viewProperties.Title,
            resultDescription: viewProperties.Description,
            resultDescriptionEditorStateJson:
              viewProperties.DescriptionLexicalStateJson,
            resultImage: viewProperties.Image,
            maxWinners: resultData.maxWinners,
            winProbabilityNumerator: resultData.winProbabilityNumerator,
            winProbabilityDenominator: resultData.winProbabilityDenominator,
            isFallback: resultData.isFallback,
            buttons: viewProperties.Buttons || [],
            prizeId: r.prizeId,
            prizeSmsId: r.prizeSmsId,
            prizeEmailId: r.prizeEmailId,
            prizeCouponOfferId: r.prizeCouponOfferId,
            prizeCouponOfferName: r.prizeCouponOfferName,
            prizeCouponOfferIssueLimit: r.prizeCouponOfferIssueLimit,
            prizeCouponOfferIssueCount: r.prizeCouponOfferIssueCount,
            prizeCouponOfferRedeemLimit: r.prizeCouponOfferRedeemLimit,
            prizeCouponOfferRedeemCount: r.prizeCouponOfferRedeemCount,
            confettiEnabled: r.properties.ConfettiEnabled
          })
        );
      }
    });

    return wedgeResults;
  }
}

export class WedgeDataWithResult extends WheelWedge {
  result: CardResult;
  name: string;
  isUnlimited: boolean = true;
  recipients: number = 0;
  resultTitle: string;
  resultDescription: string;
  resultDescriptionEditorStateJson: string;
  resultImage?: ImageDataModel;
  confettiEnabled: boolean;
  winProbabilityNumerator: number;
  winProbabilityDenominator: number;
  maxWinners: number;
  isFallback: boolean = false;
  buttons?: ButtonDataModel[];
  prizeId?: Guid;
  prizeSmsId?: Guid;
  prizeEmailId?: Guid;
  prizeCouponOfferId?: Guid;
  prizeCouponOfferName?: string;
  prizeCouponOfferIssueLimit?: number;
  prizeCouponOfferIssueCount?: number;
  prizeCouponOfferRedeemLimit?: number;
  prizeCouponOfferRedeemCount?: number;

  get hasPrizeEmail() {
    return !!this.prizeEmailId;
  }

  get hasPrizeSms() {
    return !!this.prizeSmsId;
  }

  constructor(props?: Partial<WedgeDataWithResult>) {
    super();
    props = props || {};
    Object.assign(this, props);

    this.repeatNumber = asNumber(props.repeatNumber, 1);
    this.overrideWheelColors = asBoolean(props.overrideWheelColors);

    this.backgroundImage = ImageDataModel.fromJsonOrUrl(props.backgroundImage);

    if (props.overrideWheelColors == undefined && !!this.textColor) {
      this.overrideWheelColors = true;
    }

    this.resultId = Guid.valueOrUndefined(props.resultId);

    this.result = new CardResult(props.result);
    this.prizeId = Guid.valueOrUndefined(props.prizeId);
    this.prizeEmailId = Guid.valueOrUndefined(props.prizeEmailId);
    this.prizeSmsId = Guid.valueOrUndefined(props.prizeSmsId);
    this.prizeCouponOfferId = Guid.valueOrUndefined(props.prizeCouponOfferId);
    this.buttons = mapArray(props.buttons, (b) => new ButtonDataModel(b));
    this.confettiEnabled = asBoolean(props.confettiEnabled, true);
  }
}
