import { Guid } from '@komo-tech/core/models/Guid';
import { orderByAscending } from '@komo-tech/core/models/IHasOrder';
import { mapArray } from '@komo-tech/core/utils/array';

import { CardTypes } from '@/common/models/CardTypes';
import { SitePageProperties } from '@/common/models/pages/shared/SitePageProperties';
import { SiteCard } from '@/common/models/SiteCard';
import { SiteCardHelper } from '@/common/models/SiteCardHelper';

import { PredictiveProperties } from './PredictiveProperties';
import { PredictiveStyle } from './PredictiveStyle';
import { SegmentState } from './types';
import {
  PredictiveSegment,
  SegmentPrizeCheckResult
} from './PredictiveSegment';

export class Predictive implements SiteCard {
  readonly id: Guid;
  readonly siteId: Guid;
  readonly type: CardTypes = CardTypes.Predictive;
  readonly title: string;
  readonly segments: PredictiveSegment[];
  readonly properties: PredictiveProperties;

  constructor(props?: Partial<Predictive>) {
    props = props || {};
    Object.assign(this, props);
    SiteCardHelper.applyDefaults(this, props);

    this.segments = mapArray(
      props.segments,
      (x: any) => new PredictiveSegment(x)
    ).sort(orderByAscending);

    this.properties = new PredictiveProperties({
      ...props.properties
    });
  }

  getPredictiveCoverSegmentList(): PredictiveSegment[] {
    const activeSegments = this.segments.filter(
      (segment) => segment.getState() !== SegmentState.Completed
    );
    return activeSegments || [this.segments[this.segments.length - 1]];
  }

  setAnswerRevealedImmutable(
    questionId: Guid,
    isRevealed: boolean
  ): Predictive {
    return new Predictive({
      ...this,
      segments: this.segments.map((s) =>
        s.setAnswerRevealedImmutable(questionId, isRevealed)
      )
    });
  }

  getButtonTextColor(properties: SitePageProperties) {
    const { AccentTextColor } = properties;
    const { ButtonTextColor } = this.properties;
    return ButtonTextColor || AccentTextColor || '#ffffff';
  }

  getButtonBackgroundTextColor(properties: SitePageProperties) {
    const { AccentBackgroundColor } = properties;
    const { ButtonBackgroundColor } = this.properties;
    return ButtonBackgroundColor || AccentBackgroundColor || '#000000';
  }

  getIsAdvancedStyle() {
    return this.properties.Style === PredictiveStyle.Advanced;
  }

  areSegmentsReadyForPrizeAwarding(segmentIds?: Guid[]) {
    let areAllReady = true;
    const notReadySegments: SegmentPrizeCheckResult[] = [];
    const segmentsToCheck = !!segmentIds?.length
      ? this.segments.filter((s) => segmentIds.some((x) => x.equals(s.id)))
      : this.segments;
    for (let i = 0; i < segmentsToCheck.length; i++) {
      const segmentCheck = segmentsToCheck[i].checkCanAwardPrizes();
      if (!segmentCheck.canAward) {
        areAllReady = false;
        notReadySegments.push(segmentCheck);
      }
    }

    return { readyForPrizeAwarding: areAllReady, notReadySegments };
  }

  getQuestion(questionId: Guid) {
    return this.segments
      .flatMap((x) => x.questions)
      .find((x) => Guid.equals(x.id, questionId));
  }

  getQuestions() {
    return this.segments.flatMap((x) => x.questions);
  }

  getSegment(segmentId: Guid) {
    return this.segments.find((x) => Guid.equals(x.id, segmentId));
  }

  getSegmentContainingQuestion(questionId: Guid) {
    return this.segments.find((x) =>
      x.questions.some((q) => Guid.equals(q.id, questionId))
    );
  }
}
