import { Guid } from '@komo-tech/core/models/Guid';

import { CardTypes } from '@/common/models/CardTypes';
import { SitePageProperties } from '@/common/models/pages/shared/SitePageProperties';
import { SiteCard } from '@/common/models/SiteCard';
import { SiteCardHelper } from '@/common/models/SiteCardHelper';

import {
  InstantWinModalStyleProps,
  ScratchAndWinProperties
} from '../shared/ScratchAndWinProperties';

export class FrontScratchAndWin implements SiteCard {
  id: Guid;
  siteId: Guid;
  readonly type: CardTypes = CardTypes.ScratchAndWin;
  properties: ScratchAndWinProperties;

  constructor(partial?: Partial<FrontScratchAndWin>) {
    partial = partial || {};
    Object.assign(this, partial);
    SiteCardHelper.applyDefaults(this, partial);
    this.properties = new ScratchAndWinProperties(partial?.properties);
  }

  canPlayAgain() {
    return !this.properties.PlayAgainButtonDisabled;
  }

  getCoverProps(properties: SitePageProperties) {
    return {
      image: this.properties.CoverImage,
      title: this.properties.CoverTitle,
      buttonText: this.properties.CoverButtonText,
      buttonBackgroundColor: this.getButtonBackgroundColor(properties),
      buttonTextColor: this.getButtonTextColor(properties),
      hideButton: !this.properties.CoverButtonEnabled,
      hideTitle: !this.properties.CoverTitleEnabled
    };
  }

  getButtonBackgroundColor(properties: SitePageProperties) {
    return this.properties.getButtonBackgroundColor(properties);
  }

  getButtonTextColor(properties: SitePageProperties) {
    return this.properties.getButtonTextColor(properties);
  }

  getModalStyleProps(
    properties: SitePageProperties
  ): InstantWinModalStyleProps {
    return this.properties.getModalStyleProps(properties);
  }
}
