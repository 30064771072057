export enum PickerLabelTruncationTypes {
  None = 'None',
  FirstWord = 'FirstWord',
  LastWord = 'LastWord'
}

export enum PickerLabelPosition {
  Top = 'Top',
  Right = 'Right',
  Bottom = 'Bottom',
  Left = 'Left'
}

export enum PickerLabelAlignment {
  Right = 'right',
  Center = 'center',
  Left = 'left'
}
