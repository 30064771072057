import { SiteCardProperties } from '@/common/models/SiteCardProperties';

export class VideoProperties implements SiteCardProperties {
  ButtonTextColor?: string;
  ButtonBackgroundColor?: string;
  constructor(props?: Partial<VideoProperties>) {
    props = props || {};
    Object.assign(this, props);
  }
}
