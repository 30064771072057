import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { asBoolean } from '@komo-tech/core/utils/boolean';
import { asModelOrFallback } from '@komo-tech/core/utils/string';

import { ButtonDataModel } from '@/common/models/ButtonDataModel';
import { SiteCardProperties } from '@/common/models/SiteCardProperties';

export class SwiperProperties implements SiteCardProperties {
  ButtonTextColor?: string;
  ButtonBackgroundColor?: string;

  ModalHeaderColor: string = '#FFFFFF';
  ModalHeaderBackgroundColor: string = '#000000';
  ModalHeaderImageJson?: string;
  //TODO: remove after Migrating SwiperResults to CardResults
  UsingCardResults: boolean;

  get ModalHeaderImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.ModalHeaderImageJson);
  }

  ModalBackgroundImageJson?: string;

  get ModalBackgroundImage() {
    return ImageDataModel.fromJsonOrUrl(this.ModalBackgroundImageJson);
  }

  ModalBackgroundColor: string = '#ffffff';
  ModalTextColor: string = '#000000';

  ViewAnswersButtonText?: string;
  ViewAnswersButtonColor?: string;
  ViewAnswersButtonBackgroundColor?: string;
  DefaultViewAnswersText = 'View answers';

  DefaultExplainerText =
    'Swipe right for yes and left for no. Can you get every answer correct?';
  ExplainerText?: string;

  ExplainerCtaJson?: string;
  get ExplainerCta() {
    const button = new ButtonDataModel(
      asModelOrFallback(this.ExplainerCtaJson, {
        fallback: SwiperProperties.DefaultExplainerCta
      })
    );
    if (!button.text) {
      button.text = SwiperProperties.DefaultExplainerCta.text;
    }
    return button;
  }

  static DefaultExplainerCta = new ButtonDataModel({
    backgroundColor: '#000000',
    color: '#FFFFFF',
    text: "Let's go!"
  });

  DefaultExplainerRightIndicatorText = 'Yes';
  ExplainerRightIndicatorText: string;
  DefaultExplainerRightIndicatorColor = '#2b8a3e';
  ExplainerRightIndicatorColor: string;
  DefaultExplainerLeftIndicatorText = 'No';
  ExplainerLeftIndicatorText: string;
  ExplainerLeftIndicatorColor: string;
  DefaultExplainerLeftIndicatorColor = '#c92a2a';

  get ModalHeaderProps() {
    return {
      color: this.ModalHeaderColor,
      backgroundColor: this.ModalHeaderBackgroundColor,
      image: this.ModalHeaderImage
    };
  }

  get ModalBackgroundProps() {
    return {
      image: this.ModalBackgroundImage,
      backgroundColor: this.ModalBackgroundColor
    };
  }

  constructor(props: Partial<SwiperProperties> = {}) {
    Object.assign(this, props);
    if (!this.ExplainerText) {
      this.ExplainerText = this.DefaultExplainerText;
    }
    if (!this.ExplainerLeftIndicatorText) {
      this.ExplainerLeftIndicatorText = this.DefaultExplainerLeftIndicatorText;
    }
    if (!this.ExplainerRightIndicatorText) {
      this.ExplainerRightIndicatorText =
        this.DefaultExplainerRightIndicatorText;
    }

    if (!this.ExplainerLeftIndicatorColor) {
      this.ExplainerLeftIndicatorColor =
        this.DefaultExplainerLeftIndicatorColor;
    }
    if (!this.ExplainerRightIndicatorColor) {
      this.ExplainerRightIndicatorColor =
        this.DefaultExplainerRightIndicatorColor;
    }
    this.UsingCardResults = asBoolean(props.UsingCardResults);
  }

  updateExplainerCta(change: Partial<ButtonDataModel>) {
    this.ExplainerCtaJson = JSON.stringify(
      new ButtonDataModel({ ...this.ExplainerCta, ...change })
    );
  }
}
