import { Guid } from '@komo-tech/core/models/Guid';
import { orderByAscending } from '@komo-tech/core/models/IHasOrder';
import { mapArray, shuffleArray } from '@komo-tech/core/utils/array';

import { CardTypes } from '@/common/models/CardTypes';
import { SiteCard } from '@/common/models/SiteCard';
import { SiteCardHelper } from '@/common/models/SiteCardHelper';

import { CarouselProperties } from '../shared';
import { AdminCarouselItem } from './AdminCarouselItem';

export class AdminCarousel implements SiteCard {
  id: Guid;
  siteId: Guid;
  readonly type: CardTypes = CardTypes.Carousel;
  items: AdminCarouselItem[];
  properties: CarouselProperties;

  constructor(props?: Partial<AdminCarousel>) {
    props = props || {};
    Object.assign(this, props);
    SiteCardHelper.applyDefaults(this, props);
    this.items = mapArray(
      props.items,
      (x) => new AdminCarouselItem({ ...x })
    ).sort(orderByAscending);
    this.properties = new CarouselProperties(props.properties);
  }

  sanitiseItemsOrder() {
    this.items.forEach((item, index) => {
      item.order = index + 1;
    });
  }

  isVoteCard() {
    return this.properties.VotingEnabled;
  }

  getShuffleIds(ignoreShuffle: boolean) {
    const ids = mapArray(this.items, (x) => new AdminCarouselItem(x)).map(
      (x) => x.id
    );

    if (ignoreShuffle || this.properties.DontShuffleItems) {
      return ids;
    }

    return shuffleArray(ids);
  }

  getItemsForRender(orderedIds: Guid[]) {
    return mapArray(this.items, (x) => new AdminCarouselItem(x)).sort(
      Guid.orderByIdArray(orderedIds)
    );
  }
}
