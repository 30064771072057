import { useCardDefinitions } from './_useDefinitions';
import { useFrontCardDescriptor } from './_useDescriptor';
import { useFrontCardsCacheSync } from './_useSync';
import { useFrontTypedCard } from './_useTypedCard';
import { useFrontCardViewModel } from './_useViewModel';

export const PublicCardHooks = {
  useDefinitions: useCardDefinitions,
  useDescriptor: useFrontCardDescriptor,
  useViewModel: useFrontCardViewModel,
  useSync: useFrontCardsCacheSync,
  useTyped: useFrontTypedCard,
  controlled: {}
};
