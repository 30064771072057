import { SiteCard } from '../../SiteCard';
import { BasePicker } from '../shared/BasePicker';

export class FrontPicker extends BasePicker implements SiteCard {
  constructor(props: Partial<FrontPicker> = {}) {
    super(props);
  }

  get shouldAutoPopResultsLiveData() {
    return (
      this.properties.ResultsLiveDataAutoPopup &&
      this.properties.ResultsLiveDataEnabled
    );
  }
}
