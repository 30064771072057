import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { asFloat, asNumber } from '@komo-tech/core/utils/number';
import { immerable } from 'immer';

import { SiteCardProperties } from '@/common/models/SiteCardProperties';

export class NumberGuessProperties implements SiteCardProperties {
  [immerable] = true;
  ButtonText?: string;
  DefaultButtonText: string = 'CONFIRM YOUR GUESS';
  ButtonTextColor?: string;
  ButtonBackgroundColor?: string;
  SliderImage?: string;

  get NumberGuessSliderImage() {
    return ImageDataModel.fromJsonOrUrl(this.SliderImage);
  }

  Decimals?: number;
  AnswerSuffix?: string;
  IncrementBy?: number;

  constructor(props: Partial<NumberGuessProperties>) {
    props = props || {};
    Object.assign(this, props);
    this.Decimals = asNumber(props.Decimals, 0);
    this.IncrementBy = asFloat(props.IncrementBy, 1);
  }
}
