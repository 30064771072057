import { PredictiveAnswerStyles } from './PredictiveAnswerStyles';
import { PredictiveGameplay } from './PredictiveGameplay';
import { PredictiveSegment } from './PredictiveSegment';
import { SegmentState } from './types';

export interface SliderOptions {
  max: number;
  min: number;
  tolerance?: number;
  answer?: number;
  maxPoints?: number;
  minPoints?: number;
}
export const calculateSliderPoints = (
  sliderOptions: SliderOptions,
  guess: number
) => {
  if (!isCorrectSliderGuess(sliderOptions, guess)) {
    return 0;
  }

  const minPoints = sliderOptions.minPoints ?? 1_000;
  const maxPoints = sliderOptions.maxPoints ?? 10_000;

  if (!!sliderOptions.tolerance) {
    const range = sliderOptions.tolerance;
    if (range <= 0) {
      return maxPoints;
    }

    const differenceFromAnswer = Math.abs(sliderOptions.answer - guess);
    const differenceOverRange = Math.min(
      Math.max(differenceFromAnswer / range, 0),
      1
    );
    const pointFactor = 1 - differenceOverRange;

    // scaled exponential points - try to scale based on custom min/max ranges
    // We cant just plug any min/max into the base exponential equation
    if (minPoints != 1000 || maxPoints != 10000) {
      const maxMinRange = maxPoints - minPoints;
      const regularPoints = 1000 * Math.pow(10, pointFactor);
      const scaledPoints = Math.round(
        ((regularPoints - 1000) / 9000) * maxMinRange + minPoints
      );

      return Math.min(Math.max(scaledPoints, minPoints), maxPoints);
    }

    // exponential: Points = 1000 * 10^x
    // where x goes from (0->1), which corresponds to an answer that is (far from answer->close to answer)

    const points = Math.round(minPoints * Math.pow(10, pointFactor));
    return Math.min(Math.max(points, minPoints), maxPoints);
  }

  return maxPoints;
};

export const isCorrectSliderGuess = (
  sliderOptions: SliderOptions,
  guess: number
) => {
  if (sliderOptions.answer === undefined) {
    return false;
  }

  if (!!sliderOptions.tolerance) {
    const range = sliderOptions.tolerance;
    return (
      sliderOptions.answer - range <= guess &&
      guess <= sliderOptions.answer + range
    );
  }

  return sliderOptions.answer === guess;
};

export const getTotalCorrect = (
  segments: PredictiveSegment[],
  gameplay: PredictiveGameplay
) => {
  let totalCorrect = 0;
  for (const seg of segments) {
    if (seg.getState() === SegmentState.Locked) {
      continue;
    }
    seg.questions.forEach((q) => {
      if (q.style === PredictiveAnswerStyles.Slider) {
        const selectedGuess = gameplay?.getSelectedSliderGuessValueByQuestionId(
          q.id
        );
        if (
          q.answerRevealed &&
          q.properties.isCorrectSliderGuess(selectedGuess?.selectedValue)
        ) {
          totalCorrect += 1;
        }
      } else {
        const selectedOptionId = gameplay?.getSelectedMultiChoiceOptionId(q.id);
        if (selectedOptionId && q.isOptionCorrect(selectedOptionId)) {
          totalCorrect += 1;
        }
      }
    });
  }
  return totalCorrect;
};
