import { CanvasBackground } from './CanvasBackground';
import { BlackListedWord } from './CanvasProperties';

export interface TextChoice {
  id: string;
  text: string;
}

export class CanvasCreationChoices {
  background?: CanvasBackground;
  textItems: TextChoice[];
  constructor(props?: Partial<CanvasCreationChoices>) {
    props = props || {};
    Object.assign(this, props);
    if (!this.textItems?.length) this.textItems = [];
    this.textItems = [...(props.textItems || [])];
    if (props.background) {
      this.background = new CanvasBackground(props.background);
    }
  }

  getTextRecords(fallbackText: Record<string, string>) {
    const records: Record<string, string> = {};
    if (!this.textItems?.length) return records;
    this.textItems.forEach((item) => {
      const text = (item.text || fallbackText[item.id] || '').trim();
      if (item.id && text) {
        records[item.id] = text;
      }
    });
    return records;
  }

  areTextRecordsValid(
    ids: string[] = [],
    blackListedWords: BlackListedWord[] = []
  ) {
    if (!ids.length) return true;
    const blackListsLower = blackListedWords.map((x) =>
      (x.text || '').toLowerCase()
    );

    const textItems = this.textItems;

    return ids.every((id) => {
      const item = textItems.find((x) => x.id === id);
      const itemTextLower = (item?.text || '').toLowerCase();
      if (!itemTextLower) return false;
      if (!blackListsLower.length) return true;
      return !blackListsLower.some((b) => itemTextLower.includes(b));
    });
  }
}
