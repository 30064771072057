import { asBoolean } from '@komo-tech/core/utils/boolean';
import isNil from 'lodash/isNil';

import { SitePageProperties } from '@/common/models/pages/shared/SitePageProperties';
import { SiteCardProperties } from '@/common/models/SiteCardProperties';
import {
  getPrimaryBackgroundColor,
  getPrimaryTextColor
} from '@/common/utils/ButtonFunctions';

import { TriviaFormVariant } from './TriviaFormVariant';

export class TriviaProperties implements SiteCardProperties {
  readonly DefaultCoverButtonText = 'PLAY NOW';
  CoverButtonText?: string;
  CoverButtonEnabled?: boolean;

  ButtonTextColor?: string;
  ButtonBackgroundColor?: string;

  FormVariant: TriviaFormVariant = TriviaFormVariant.Basic;

  constructor(props?: Partial<TriviaProperties>) {
    props = props || {};
    Object.assign(this, props);

    if (!this.CoverButtonText) {
      this.CoverButtonText = this.DefaultCoverButtonText;
    }

    if (isNil(this.CoverButtonEnabled)) {
      this.CoverButtonEnabled = true;
    } else {
      this.CoverButtonEnabled = asBoolean(this.CoverButtonEnabled);
    }
  }

  getButtonTextColor(properties: SitePageProperties) {
    const { AccentTextColor } = properties;
    const { ButtonTextColor } = this;
    return ButtonTextColor || AccentTextColor || '#FFFFFF';
  }

  getButtonBackgroundTextColor(properties: SitePageProperties) {
    const { AccentBackgroundColor } = properties;
    const { ButtonBackgroundColor } = this;
    return ButtonBackgroundColor || AccentBackgroundColor || '#000000';
  }

  getCoverButtonProps(properties: SitePageProperties) {
    return {
      buttonBackgroundColor: getPrimaryBackgroundColor({
        overrideColor: this.ButtonBackgroundColor,
        properties
      }),
      buttonColor: getPrimaryTextColor({
        overrideColor: this.ButtonTextColor,
        properties
      }),
      buttonText: this.CoverButtonText,
      buttonEnabled: this.CoverButtonEnabled
    };
  }
}
