import { asBoolean } from '@komo-tech/core/utils/boolean';

import { SiteCardProperties } from '@/common/models/SiteCardProperties';

export class CountdownClockProperties implements SiteCardProperties {
  ButtonTextColor?: string;
  ButtonBackgroundColor?: string;
  ButtonText?: string;
  DefaultButtonText: string = 'CLICK HERE';
  ButtonUrl?: string;
  CallToActionEnabled?: boolean;

  CountdownNumberBackgroundColor: string;
  CountdownNumberTextColor: string;
  CountdownLabelTextColor: string;
  FullDateFormat?: string;

  constructor(props?: Partial<CountdownClockProperties>) {
    props = props || {};
    Object.assign(this, props);
    this.DefaultButtonText = 'CLICK HERE';
    this.CallToActionEnabled = asBoolean(props.CallToActionEnabled);
    if (!this.ButtonText) this.ButtonText = this.DefaultButtonText;
  }
}
