export enum MemoryChallengeStreakVariant {
  Addition = 'Addition'
}

export class MemoryChallengeScoreConfig {
  matchPoints: number = 0;
  missPoints: number = 0;
  streakVariant: MemoryChallengeStreakVariant =
    MemoryChallengeStreakVariant.Addition;
  streakValue: number = 0;
  remainingSecondBonusPoints: number = 0;
  constructor(props?: Partial<MemoryChallengeScoreConfig>) {
    props = props || {};
    Object.assign(this, props);
  }

  calculateMiss() {
    return this.missPoints;
  }

  calculateMatch(streakProp: number): [number, MemoryMatchPointsBreakdown] {
    const streak = Math.max(streakProp - 1, 0);
    const breakdown: MemoryMatchPointsBreakdown = {
      match: 0,
      streak: 0
    };

    breakdown.match = this.matchPoints;
    if (streak > 0) {
      switch (this.streakVariant) {
        case MemoryChallengeStreakVariant.Addition:
          breakdown.streak = streak * this.streakValue;
          break;
      }
    }

    return [breakdown.match + breakdown.streak, breakdown];
  }

  static default() {
    return new MemoryChallengeScoreConfig({
      matchPoints: 500,
      missPoints: -50,
      streakValue: 50,
      streakVariant: MemoryChallengeStreakVariant.Addition,
      remainingSecondBonusPoints: 1000
    });
  }

  static legacy() {
    return new MemoryChallengeScoreConfig({
      matchPoints: 0,
      missPoints: 0,
      streakValue: 0,
      streakVariant: MemoryChallengeStreakVariant.Addition,
      remainingSecondBonusPoints: 1000
    });
  }

  static fromJsonOrDefault(json?: string) {
    if (!json) return MemoryChallengeScoreConfig.default();
    try {
      return new MemoryChallengeScoreConfig(JSON.parse(json));
    } catch {
      return MemoryChallengeScoreConfig.default();
    }
  }
}

export interface MemoryMatchPointsBreakdown {
  match: number;
  streak: number;
}
