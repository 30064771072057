import { ColorRGBA } from '@komo-tech/core/models/ColorRGBA';
import { Guid } from '@komo-tech/core/models/Guid';
import { mapArray } from '@komo-tech/core/utils/array';

import { CardTypes } from '@/common/models/CardTypes';
import { SiteCard } from '@/common/models/SiteCard';
import { SiteCardHelper } from '@/common/models/SiteCardHelper';

import { MemoryChallengeProperties } from '../shared/MemoryChallengeProperties';
import { AdminMemoryTile } from './AdminMemoryTile';

export class AdminMemoryChallenge implements SiteCard {
  id: Guid;
  siteId: Guid;
  readonly type: CardTypes = CardTypes.MemoryChallenge;
  name: string;
  memoryTiles: AdminMemoryTile[];
  properties: MemoryChallengeProperties;

  constructor(props?: Partial<AdminMemoryChallenge>) {
    props = props || {};
    Object.assign(this, props);
    SiteCardHelper.applyDefaults(this, props);
    this.properties = new MemoryChallengeProperties(props.properties);
    this.memoryTiles = (
      mapArray(props.memoryTiles, (x) => new AdminMemoryTile(x)) || []
    ).sort((a, b) => a.pairNumber - b.pairNumber);
  }

  get modalHeaderProps() {
    return {
      HeaderImage: this.properties.HeaderImageModel,
      HeaderTextColor: this.properties.HeaderTextColor,
      HeaderBackgroundColor: this.properties.HeaderBackgroundColor
    };
  }

  get timerProps() {
    return {
      SecondsGameplay: this.properties.SecondsGameplay,
      TimerTextColor: this.properties.TimerTextColor,
      TimerBackgroundColor: this.properties.TimerBackgroundColor
    };
  }

  get scoringProps() {
    return {
      ScoringEnabled: !this.properties.LegacyScoring,
      BackgroundColor: new ColorRGBA(this.properties.ScoringBackgroundColor)
        .hexa,
      TextColor: this.properties.ScoringTextColor,
      ProgressBarColor:
        this.properties.ScoringProgressBarColor ||
        this.properties.ScoringTextColor,
      ShowProgressBar: !this.properties.ScoringProgressBarDisabled
    };
  }

  getInstructionsProps(
    buttonTextColorFallback: string,
    buttonBackgroundColorFallback: string
  ) {
    return {
      InstructionsButtonText: this.properties.InstructionsButtonText,
      InstructionsButtonTextColor:
        this.properties.InstructionsButtonTextColor || buttonTextColorFallback,
      InstructionsButtonBackgroundColor:
        this.properties.InstructionsButtonBackgroundColor ||
        buttonBackgroundColorFallback,
      InstructionsHeaderText: this.properties.InstructionsHeaderText,
      InstructionsHeaderColor: this.properties.InstructionsHeaderColor,
      InstructionsDescriptionText: this.properties.InstructionsDescriptionText,
      InstructionsDescriptionColor: this.properties.InstructionsDescriptionColor
    };
  }
}
