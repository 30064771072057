import { Guid } from '@komo-tech/core/models/Guid';
import { HeightWidth } from '@komo-tech/core/models/HeightWidth';
import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import isNil from 'lodash/isNil';

import { CardTypes } from '@/common/models/CardTypes';
import { SitePageProperties } from '@/common/models/pages/shared/SitePageProperties';
import { SiteCard } from '@/common/models/SiteCard';
import { SiteCardHelper } from '@/common/models/SiteCardHelper';

import { CanvasBackground } from '../shared/CanvasBackground';
import { CanvasProperties } from '../shared/CanvasProperties';
import { getCanvasBackground, getCanvasItems } from '../shared/functions';

export class FrontCanvas implements SiteCard {
  id: Guid;
  siteId: Guid;
  readonly type: CardTypes = CardTypes.Canvas;
  stateVersion: number;
  widthRatio: number;
  heightRatio: number;
  itemsJson: string;
  backgroundJson: string;
  imageUrl: string;
  properties: CanvasProperties;

  get image() {
    return ImageDataModel.fromJsonOrUrl(this.imageUrl);
  }

  constructor(props?: Partial<FrontCanvas>) {
    props = props || {};
    Object.assign(this, props);
    SiteCardHelper.applyDefaults(this, props);
    this.properties = new CanvasProperties(props.properties);

    if (isNil(this.stateVersion)) this.stateVersion = 0;
    if (isNil(this.widthRatio) && isNil(this.heightRatio)) {
      this.widthRatio = 16;
      this.heightRatio = 9;
    }
  }

  getSubmitButtonProps(properties: SitePageProperties) {
    return {
      color: this.properties.getButtonTextColor(properties),
      backgroundColor: this.properties.getButtonBackgroundColor(properties)
    };
  }

  getButtonTextColor(properties: SitePageProperties) {
    return this.properties.getButtonTextColor(properties);
  }

  getButtonBackgroundColor(properties: SitePageProperties) {
    return this.properties.getButtonBackgroundColor(properties);
  }

  getCoverButtonTextProps() {
    return {
      text: this.properties.CoverSubmitText,
      defaultText: this.properties.DefaultCoverSubmitText
    };
  }

  getState() {
    return {
      items: getCanvasItems(this.itemsJson),
      background: getCanvasBackground(this.backgroundJson),
      size: this.getSize()
    };
  }

  getSize() {
    const size: HeightWidth = {
      height: this.heightRatio,
      width: this.widthRatio
    };
    return size;
  }

  getAllBackgroundOptions() {
    const defaultBackground = getCanvasBackground(this.backgroundJson);
    const backgrounds: CanvasBackground[] = [defaultBackground];
    if (this.properties.BackgroundChoicesEnabled) {
      const options = this.properties.getBackgroundChoices();
      if (options.length) {
        options.forEach((o) => backgrounds.push(o));
      }
    }

    return {
      default: defaultBackground,
      all: backgrounds
    };
  }

  getInitialCreationBackground(backgroundChoice?: CanvasBackground) {
    const options = this.getAllBackgroundOptions();
    const { BackgroundChoicesEnabled } = this.properties;
    if (!BackgroundChoicesEnabled || !backgroundChoice) return options.default;
    if (backgroundChoice.id) {
      const index = options.all.findIndex((x) => x.id === backgroundChoice.id);
      if (index >= 0) return options.all[index];
    }

    return options.default;
  }
}
