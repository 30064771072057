import { Guid } from '@komo-tech/core/models/Guid';
import { HeightWidth } from '@komo-tech/core/models/HeightWidth';
import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { mapArray } from '@komo-tech/core/utils/array';
import { asBoolean } from '@komo-tech/core/utils/boolean';
import {
  asNumber,
  asNumberOrUndefined,
  roundTo2Decimals
} from '@komo-tech/core/utils/number';
import { getHeightByRatio, getWidthByRatio } from '@komo-tech/core/utils/size';

import { IPicker } from './IPicker';
import { PickerTag } from './PickerTag';
import { PickerLabelTruncationTypes } from './PickerLabelEnums';

export class PickerSlot {
  id: Guid;
  properties: PickerSlotProperties;
  tags: PickerTag[];

  set name(v) {
    this.properties.Name = v;
  }

  get name() {
    return this.properties.Name;
  }

  get adminName() {
    return this.properties.Name || 'Untitled';
  }

  get image() {
    return ImageDataModel.fromJsonOrUrl(this.properties.ImageJson);
  }

  get hasImage() {
    return !!this.properties.ImageJson;
  }

  get hasHeightWidth() {
    const { height, width } = this.attrs;
    return !!width && !!height;
  }

  get attrs() {
    try {
      return JSON.parse(this.properties.AttrsJson) as PickerSlotAttrs;
    } catch {
      return new PickerSlotAttrs();
    }
  }

  set attrs(v: PickerSlotAttrs) {
    this.properties.AttrsJson = JSON.stringify(v);
  }

  get widthParsed() {
    if (!this.attrs.width) return;
    return roundTo2Decimals(this.attrs.width);
  }

  get heightParsed() {
    if (!this.attrs.height) return;
    return roundTo2Decimals(this.attrs.height);
  }

  constructor(props: Partial<PickerSlot> = {}) {
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.tags = mapArray(props.tags, (x) => new PickerTag(x));
    this.properties = new PickerSlotProperties(props.properties);
  }

  updateAttrs(attrs: Partial<PickerSlotAttrs>) {
    const current = this.attrs;
    const updated = new PickerSlotAttrs({ ...current, ...attrs });
    this.attrs = updated;
  }

  getImageAspectRatio(card: IPicker) {
    return !!this.image
      ? this.image.height / this.image.width
      : card.slotDefaultImageAspectRatio;
  }

  setImage(v: ImageDataModel | undefined) {
    if (!v) {
      this.properties.ImageJson = undefined;
      return;
    }
    this.properties.ImageJson = JSON.stringify(v);

    //If height and width set, ensure new image aspect ratio is applied
    if (this.hasHeightWidth) {
      const imageRatio = v.height / v.width;
      const currentRatio = this.heightParsed / this.widthParsed;
      if (imageRatio === currentRatio) return;

      // set height width based on the smaller dimension option image so it doesn't extend slot image boundary
      if (v.width < v.height) {
        this.updateAttrs({
          width: getWidthByRatio(
            { width: v.width, height: v.height },
            this.attrs.height
          )
        });
      } else {
        this.updateAttrs({
          height: getHeightByRatio(
            { width: v.width, height: v.height },
            this.attrs.width
          )
        });
      }
    }
  }

  getCalculatedSize(card: IPicker, stageWidth: number): HeightWidth {
    if (this.hasHeightWidth) {
      return { height: this.heightParsed, width: this.widthParsed };
    }

    const width = roundTo2Decimals(stageWidth / 8);
    const aspectRatio = this.getImageAspectRatio(card);
    return {
      width: width,
      height: roundTo2Decimals(width * aspectRatio)
    };
  }

  getSanitiseName(
    truncation: PickerLabelTruncationTypes = PickerLabelTruncationTypes.None
  ) {
    if (!this.name || truncation === PickerLabelTruncationTypes.None) {
      return this.name;
    }

    const split = this.name.split(' ');
    if (split.length <= 1) {
      return this.name;
    }
    if (truncation === PickerLabelTruncationTypes.FirstWord) {
      split[0] = `${split[0][0].toUpperCase()}.`;
    } else if (truncation === PickerLabelTruncationTypes.LastWord) {
      const lastIndex = split.length - 1;
      split[lastIndex] = `${split[lastIndex][0].toUpperCase()}.`;
    }

    return split.join(' ');
  }
}

export class PickerSlotProperties {
  Name: string;
  ImageJson: string;
  AttrsJson: string;
  Required: boolean;

  constructor(props?: Partial<PickerSlotProperties>) {
    props = props || {};
    Object.assign(this, props);
    this.Required = asBoolean(props.Required, true);
  }
}

export class PickerSlotAttrs {
  x: number;
  y: number;
  height: number;
  width: number;
  stageSize?: HeightWidth;
  fontSize?: number;
  labelEnabled: boolean;

  constructor(props: Partial<PickerSlotAttrs> = {}) {
    Object.assign(this, props);
    this.x = asNumber(props.x, 0);
    this.y = asNumber(props.y, 0);
    this.fontSize = asNumberOrUndefined(props.fontSize);
    this.labelEnabled = asBoolean(props.labelEnabled, true);
  }
}
