export enum CardSubTypes {
  Image = 'Image',
  ClickBanner = 'ClickBanner',
  ButtonImage = 'ButtonImage',
  WordsOrLess = 'WordsOrLess',
  SecretCode = 'SecretCode',
  ReceiptUploader = 'ReceiptUploader',

  Carousel = 'Carousel',
  Vote = 'Vote',

  MemeGenerator = 'MemeGenerator',
  PhotoFrame = 'PhotoFrame',

  Video = 'Video',
  YouTube = 'YouTube',
  Vimeo = 'Vimeo',
  Twitch = 'Twitch',
  FacebookWatch = 'FacebookWatch',

  Poll = 'Poll',
  MultiPoll = 'MultiPoll',
  Quiz = 'Quiz',
  Personality = 'Personality',

  AppleMusic = 'AppleMusic',
  Iframe = 'Iframe',
  Html = 'Html',
  Spotify = 'Spotify',
  TikTok = 'TikTok',
  Twitter = 'Twitter',
  Instagram = 'Instagram',
  Facebook = 'Facebook',
  Issuu = 'Issuu',
  Matterport = 'Matterport',
  LinkedIn = 'LinkedIn',
  Amondo = 'Amondo',
  GoogleMaps = 'GoogleMaps'
}

export type EmbedSubType =
  | CardSubTypes.AppleMusic
  | CardSubTypes.Iframe
  | CardSubTypes.Html
  | CardSubTypes.Spotify
  | CardSubTypes.TikTok
  | CardSubTypes.Twitter
  | CardSubTypes.Instagram
  | CardSubTypes.Facebook
  | CardSubTypes.Issuu
  | CardSubTypes.Matterport
  | CardSubTypes.LinkedIn
  | CardSubTypes.Amondo
  | CardSubTypes.GoogleMaps;

export type VideoSubType =
  | CardSubTypes.Video
  | CardSubTypes.YouTube
  | CardSubTypes.Vimeo
  | CardSubTypes.Twitch
  | CardSubTypes.FacebookWatch;
