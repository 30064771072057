export enum TreasureHuntVariants {
  Unordered = 'Unordered',
  Ordered = 'Ordered'
}

export const getTreasureHuntVariantDescription = (
  variant: TreasureHuntVariants
): string => {
  switch (variant) {
    case TreasureHuntVariants.Ordered:
      return 'Participants have to complete checkpoints in order. Completing a checkpoint will unlock the next.';
    case TreasureHuntVariants.Unordered:
      return 'Participants can complete the checkpoints in any order.';
    default:
      return '';
  }
};
