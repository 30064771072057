import { Guid } from '@komo-tech/core/models/Guid';
import { asBoolean } from '@komo-tech/core/utils/boolean';

import { SwiperResultProperties } from '../shared/SwiperResultProperties';

export class FrontSwiperResult {
  id: Guid;
  swiperId: Guid;
  name: string;
  isDefault: boolean;
  properties: SwiperResultProperties;

  constructor(props?: Partial<FrontSwiperResult>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.swiperId = Guid.valueOrNew(props.swiperId);
    this.isDefault = asBoolean(props.isDefault);
    this.properties = new SwiperResultProperties(props.properties);
  }
}
