import { Guid } from '@komo-tech/core/models/Guid';

import { CardTypes } from '@/common/models/CardTypes';
import { SitePageProperties } from '@/common/models/pages/shared/SitePageProperties';
import { SiteCard } from '@/common/models/SiteCard';

import { SiteCardHelper } from '../../SiteCardHelper';
import { InstantWinProperties } from '../shared/InstantWinProperties';
import { InstantWinScreen } from '../shared/types';

export class FrontInstantWin implements SiteCard {
  id: Guid;
  siteId: Guid;
  readonly type: CardTypes = CardTypes.InstantWin;
  properties: InstantWinProperties;

  constructor(partial?: Partial<FrontInstantWin>) {
    partial = partial || {};
    Object.assign(this, partial);
    SiteCardHelper.applyDefaults(this, partial);
    this.properties = new InstantWinProperties(partial?.properties);
  }

  getInstantWinScreen(): InstantWinScreen {
    return this.properties.getInstantWinScreen();
  }

  getCoverProps(properties: SitePageProperties) {
    return {
      image: this.properties.CoverImage,
      hideTitle: !this.properties.CoverTitleEnabled,
      title: this.properties.CoverTitleEnabled
        ? this.properties.CoverTitle
        : null,
      buttonText: this.properties.CoverButtonText,
      buttonBackgroundColor: this.getButtonBackgroundColor(properties),
      buttonTextColor: this.getButtonTextColor(properties)
    };
  }

  getButtonBackgroundColor(properties: SitePageProperties) {
    return this.properties.getButtonBackgroundColor(properties);
  }

  getButtonTextColor(properties: SitePageProperties) {
    return this.properties.getButtonTextColor(properties);
  }

  getModalStyleProps(properties: SitePageProperties) {
    return this.properties.getModalStyleProps(properties);
  }
}
