import { Guid } from '@komo-tech/core/models/Guid';

export const enum KnownTags {
  Unknown = 'Unknown',
  Site = 'Site',
  Card = 'Card',
  Trivia = 'Trivia',
  Coupon = 'Coupon',
  UserSession = 'UserSession',
  PredictorSegment = 'PredictorSegment'
}

export const getTagScope = (knownTag: KnownTags) => {
  switch (knownTag) {
    case KnownTags.Site:
      return 'Site:';
    case KnownTags.Card:
      return 'Card:';
    case KnownTags.Trivia:
      return 'Trivia:';
    case KnownTags.Coupon:
      return 'Coupon:';
    case KnownTags.UserSession:
      return 'User-Session:';
    case KnownTags.PredictorSegment:
      return 'Predictor-Segment:';
    default:
      console.error('unknown tag type', knownTag);
      return '';
  }
};

export const getTagEntityIdOrDefault = (tag: string) =>
  Guid.valueOrUndefined(tag?.split(':')[1]);
