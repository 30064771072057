import { Guid } from '@komo-tech/core/models/Guid';
import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { mapArray } from '@komo-tech/core/utils/array';
import { asBoolean } from '@komo-tech/core/utils/boolean';

import { ExternalLinkButtonModel } from '@/common/models/ExternalLinkButtonModel';

export class InstantWinScreen {
  imageUrl: string;

  get Image() {
    return ImageDataModel.fromJsonOrUrl(this.imageUrl);
  }

  videoUrl?: string;
  drawVideoUrl?: string;
  title: string;
  description: string;
  descriptionEditorStateJson: string;
  textColor?: string;
  isPrizeScreen: boolean;
  prizeId?: Guid;
  hasForm: boolean;
  formCompleted: boolean;
  extraButtons: ExternalLinkButtonModel[];
  confettiEnabled: boolean;

  get shouldPopConfetti() {
    return this.isPrizeScreen && this.confettiEnabled;
  }

  constructor(props?: Partial<InstantWinScreen>) {
    props = props || {};
    Object.assign(this, props);
    this.prizeId = Guid.valueOrUndefined(this.prizeId);
    this.isPrizeScreen = asBoolean(props.isPrizeScreen);
    this.hasForm = asBoolean(props.hasForm);
    this.formCompleted = asBoolean(props.formCompleted);
    this.extraButtons = mapArray(
      props.extraButtons,
      (x) => new ExternalLinkButtonModel(x)
    );
    this.confettiEnabled = asBoolean(props.confettiEnabled, true);
  }

  hasUncompletedForm() {
    return !this.formCompleted && this.hasForm;
  }
}

export interface InstantWinScreenProps {
  image?: ImageDataModel;
  videoUrl?: string;
  drawVideoUrl?: string;
  title: string;
  description: string;
  textColor?: string;
  isPrizeScreen: boolean;
  hasForm: boolean;
  formCompleted: boolean;
}

export interface AdminInstantWinDrawingScreenProps {
  videoUrl: string;
  siteId: Guid;
  cardId: Guid;
  onUpdateVideoUrlAsync: (url: string) => Promise<any>;
}

export const InstantWinProbabilityDenominators = [
  10, 100, 1_000, 10_000, 100_000, 1_000_000
] as const;
export type InstantWinProbabilityDenominatorsType =
  (typeof InstantWinProbabilityDenominators)[number];

export interface OnUpdateInstantWinProbabilityArgs {
  numerator: number;
  denominator: InstantWinProbabilityDenominatorsType;
}
