import { Guid } from '@komo-tech/core/models/Guid';
import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { utcDateOrUndefined } from '@komo-tech/core/utils/date';

import { CardTypes } from '@/common/models/CardTypes';
import { CountdownClockAction } from '@/common/models/countdown-clock/CountdownClockAction';
import { CountdownClockProperties } from '@/common/models/countdown-clock/CountdownClockProperties';
import { SitePageProperties } from '@/common/models/pages/shared/SitePageProperties';
import { SiteCard } from '@/common/models/SiteCard';
import { SiteCardHelper } from '@/common/models/SiteCardHelper';
import {
  getPrimaryBackgroundColor,
  getPrimaryTextColor
} from '@/common/utils/ButtonFunctions';

export class CountdownClock implements SiteCard {
  id: Guid;

  siteId: Guid;

  readonly type: CardTypes = CardTypes.Countdown;

  name: string;

  imageUrl: string = '';

  get image() {
    return ImageDataModel.fromJsonOrUrl(this.imageUrl);
  }

  endedAt: Date;

  action = CountdownClockAction.Unpublish;

  properties: CountdownClockProperties;

  constructor(props?: Partial<CountdownClock>) {
    props = props || {};
    Object.assign(this, props);
    SiteCardHelper.applyDefaults(this, props);
    this.endedAt = utcDateOrUndefined(props.endedAt);
    this.properties = new CountdownClockProperties(props.properties);
  }

  getButtonTextColor(properties: SitePageProperties) {
    return getPrimaryTextColor({
      card: this,
      properties
    });
  }

  getButtonBackgroundColor(properties: SitePageProperties) {
    return getPrimaryBackgroundColor({
      card: this,
      properties
    });
  }

  getStyleProps(properties: SitePageProperties) {
    return {
      buttonColor: this.getButtonTextColor(properties),
      buttonBackgroundColor: this.getButtonBackgroundColor(properties),
      numberColor: getPrimaryTextColor({
        overrideColor: this.properties.CountdownNumberTextColor,
        card: this,
        properties
      }),
      numberBackground: getPrimaryBackgroundColor({
        overrideColor: this.properties.CountdownNumberBackgroundColor,
        card: this,
        properties
      }),
      dateColor: getPrimaryTextColor({
        overrideColor: this.properties.CountdownLabelTextColor,
        card: this,
        properties
      }),
      fullDateFormat:
        this.properties.FullDateFormat || CountdownClock.DefaultDateFormat
    };
  }

  static DefaultDateFormat = 'd LLLL yyyy h:mmaaa';
}
