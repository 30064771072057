import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { CardDefinition } from '@/common/models/CardDefinition';

import { PublicCardActions as Actions } from '../_actions';
import { PublicCardsCacheKeys as CacheKeys } from '../_cacheKeys';

export const useCardDefinitions = (
  options?: UseQueryOptions<CardDefinition[]>
) => {
  const query = useQuery(
    CacheKeys.definitions,
    () => Actions.getDefinitionsAsync(),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  );

  const isLoading = query.isLoading || query.isInitialLoading;
  return [query.data, isLoading] as const;
};
