import { asBoolean } from '@komo-tech/core/utils/boolean';

export class QuizResultProperties {
  PlayAgainDisabled: boolean;
  descriptionLexicalStateJson?: string;

  constructor(props?: Partial<QuizResultProperties>) {
    props = props || {};
    Object.assign(this, props);
    this.PlayAgainDisabled = asBoolean(props.PlayAgainDisabled);
  }
}
