import { CardTypes } from '@/common/models/CardTypes';

export const getCardRoute = (type: CardTypes) => {
  switch (type) {
    case CardTypes.Quiz:
      return 'quizzes';
    case CardTypes.NumberGuess:
      return 'number-guesses';
    case CardTypes.Leaderboard:
      return 'legacy-trivia-leaderboards';
    case CardTypes.Canvas:
      return 'canvases';
    case CardTypes.ScratchAndWin:
      return 'scratch-and-wins';
    case CardTypes.InstantWin:
      return 'instant-wins';
    case CardTypes.MemoryChallenge:
      return 'memory-challenges';
    case CardTypes.SpinToWin:
      return 'spin-to-wins';
    case CardTypes.LiveSurvey:
      return 'live-surveys';
    case CardTypes.TreasureHunt:
      return 'treasure-hunts';
    case CardTypes.Carousel:
    case CardTypes.Embed:
    case CardTypes.Predictive:
    case CardTypes.Upload:
    case CardTypes.Countdown:
    case CardTypes.Picker:
    case CardTypes.Swiper:
    case CardTypes.Image:
    case CardTypes.Trivia:
    case CardTypes.Video:
    case CardTypes.Fact:
    case CardTypes.Checklist:
      return `${type.toLowerCase()}s`;
    default:
      // noinspection UnnecessaryLocalVariableJS
      const unsupported: never = type;
      console.error('Unsupported type  ', unsupported);
      return `${(type as string).toLowerCase()}s`;
  }
};
